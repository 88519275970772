@charset "UTF-8";

.data_table {
    th,
    td {
        padding: 3px;
    }
    tr:not(.list_title) {
        &:hover {
            background-color: lighten($gray2, 8%);
        }
    }
}

.data_table--num {
    width: 10%;
}

.data--tab {
    justify-content: space-around;
    border-bottom: 3px solid #8fc593;
    margin-bottom: 20px;
    @include res($break) {
        display: block;
        border-bottom: none;
        overflow: initial;
        li {
            width: 100%;
            border-bottom: 3px solid $green;
            &:nth-child(n + 1) {
                margin-bottom: 10px;
            }
        }
    }
}
