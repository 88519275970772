@charset "UTF-8";

.top_sec_title {
    position: relative;
    padding-top: 212px;

    >div {
        position: relative;
        z-index: 3;
        background-image: url("../img/top/top_soil_back.png");
        background-color: $sub_color;

        &::before {
            position: absolute;
            top: -18px;
            content: "";
            display: block;
            width: 100%;
            height: 18px;
            background-image: url("../img/top/top_soil_back_up.png");
        }
    }

    .top_title_circle {
        display: block;
        z-index: 10;
        position: absolute;
        top: -196px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 50%;
        max-width: 352px;
        @include shadow1;

        .top_title_img {
            position: absolute;
            top: 7.386363636%;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 57.954545454%;
            pointer-events: none;
        }

        .top_title_logo {
            position: absolute;
            top: 60.795454545%;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 65.909090909%;
            pointer-events: none;

            p {
                @include tegaki;
                @include fontsize(32);
                line-height: 1.2;
                color: $gray_li;
            }
        }

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        @include hack(11) {
            width: 352px;
        }
    }

    .top_title_nav_wrap {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .top_title_nav {
            background-color: $sub_color_li;
            width: 33.333333333%;
            margin-top: 16px;
            border-radius: 3px;

            .top_title_nav_menu {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                li {
                    width: 100%;

                    a {
                        @include fontsize(30);
                        display: block;
                        padding-top: 20px;
                        padding-bottom: 20px;

                        &:hover {
                            background-color: lighten($sub_color_li, 10%);
                        }
                    }

                    .top_menu1 {
                        border-radius: 3px 0 0 0;
                    }

                    .top_menu2 {
                        border-radius: 0 3px 0 0;
                    }

                    .top_menu3 {
                        border-radius: 0 0 3px 3px;
                    }
                }

                >li {
                    &:last-child {
                        border-top: 2px solid $sub_color;
                    }
                }

                .top_title_nav_half {
                    display: flex;

                    li {
                        &:first-child {
                            border-right: 2px solid $sub_color;
                        }
                    }
                }

                .top_title_nav_nam {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    @include fontsize(30);

                    .top_shop {
                        position: relative;
                        padding-right: 20%;
                    }

                    .top_nam {
                        position: absolute;
                        display: inline-block;
                        top: -2px;
                        right: 5%;
                        line-height: 1;
                        @include fontsize(60);
                        @include teko;
                        font-weight: bold;
                        color: #ab1919;
                    }

                    @include res(1050) {
                        .top_nam {
                            right: 0;
                        }
                    }

                    @include res($res900) {
                        @include fontsize(20);

                        .top_shop {
                            padding-right: 30%;
                        }

                        .top_nam {
                            top: -5px;
                            right: 5%;
                            @include fontsize(50);
                        }
                    }

                    @include res($break) {
                        @include fontsize(30);

                        .top_shop {
                            padding-right: 20%;
                        }

                        .top_nam {
                            top: -2px;
                            right: 5%;
                        }
                    }
                }
            }
        }
    }

    .top_title_function {
        margin-top: 32px;
        padding-bottom: 32px;
        display: flex;
        justify-content: space-between;

        ul {
            display: flex;
            justify-content: space-between;
            width: 33.333333333%;

            li {
                width: 26%;

                dd {
                    height: 52px;
                    padding-bottom: 8px;

                    img {
                        width: auto;
                        height: 100%;
                        transform: scale(1);
                        opacity: 1;
                        transition: all 0.2s;
                    }
                }

                dt {
                    background-color: $sub_color_li;
                    transition: all 0.2s;
                    border-radius: 3px;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

                @include res(1000) {
                    @include fontsize(14);
                }
            }

            &:first-child {
                justify-content: center;

                li {
                    &:first-child {
                        margin-right: 48px;
                    }
                }
            }
        }
    }

    .top_infinite_loop {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $gray;
        width: 100%;
        height: 212px;
        pointer-events: none;
        overflow: hidden;

        li {
            margin-right: 8px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &::before {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background-image: url("../img/top/slide_back.png");
        background-repeat: repeat;
    }

    @include res($res900) {
        .top_title_nav_wrap {
            .top_title_nav {
                width: 27%;
            }
        }

        .top_title_function {
            ul {
                &:first-child {
                    justify-content: space-between;
                    width: 40%;

                    li {
                        margin-right: 48px;
                    }
                }

                &:last-child {
                    width: 60%;
                }
            }
        }
    }

    @include res($break) {
        .top_title_nav_wrap {
            padding-top: 20%;
            padding-bottom: 32px;
            display: block;

            .top_title_nav {
                width: 100%;
            }
        }

        .top_title_function {
            display: none;
        }
    }

    @include res($res500) {
        .top_title_circle {
            top: -182px;
            max-width: 290px;
        }

        .top_title_nav_wrap {
            padding-top: 30%;
        }
    }
}

.top_sec_trd {
    .top_title_trd {
        top: -94px;
        z-index: 3;
    }

    .top_sec_trd_img {
        margin-top: -46px;
        display: flex;
        justify-content: flex-start;
    }

    .top_sec_trd_txt {
        padding-top: 8px;
        color: $sub_color;
    }

    @include res($res900) {
        padding-top: 16px;

        .top_title_trd {
            top: 0;
        }

        .top_sec_trd_img {
            flex-wrap: wrap;
            margin-top: 35px;

            article {
                width: 32.1347031%;

                &:nth-child(3) {
                    margin-right: 0;
                }

                &:nth-child(n + 4) {
                    margin-top: 16px;
                }
            }
        }
    }
}

.top_sec_wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 32px;
    padding-bottom: 32px;

    .top_sec_lft {
        width: 100%;

        .top_sec_mdl_wrap {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }

    .top_sec_rgh {
        padding-top: 32px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        section {
            width: 49.5%;
        }
    }

    @include res($res800) {
        display: block;

        .top_sec_lft,
        .top_sec_rgh {
            display: block;

            section {
                width: 100%;
            }
        }

        .top_sec_rgh {
            padding-top: 32px;
        }
    }

    @include res($res800) {
        .top_sec_lft {
            .top_sec_mdl_wrap {
                a {
                    width: 25%;
                }
            }
        }
    }
}

.top_sec_shp {
    padding-bottom: 32px;
}

.top_sec_gui {
    background-color: $main_color_li;
    padding-top: 60px -24px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pr_title_balloon {
        z-index: 1;
        bottom: -24px;
    }

    .guide_box {
        display: flex;
        justify-content: space-between;

        a {
            position: relative;
            display: block;
            color: #fff;
            @include fontsize(24);
            width: 49.229452054%;
            padding-top: 24px;
            padding-bottom: 24px;
            background-repeat: no-repeat;

            span {
                position: relative;
                z-index: 1;
            }

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-image: url("../img/top/slide_back2.png");
                background-color: rgba(0, 0, 0, 0.5);
            }
        }

        .ft_shop_guide {
            background-image: url("../img/top/top_shop_guide.jpg");
            background-size: 115%;
            background-position: 42% 68%;
        }

        .ft_user_guide {
            background-image: url("../img/top/top_user_guide.jpg");
            background-size: cover;
            background-position: 50% 57%;
        }
    }

    @include res($break) {
        .guide_box {
            display: block;

            a {
                width: 100%;
                margin-top: 8px;
                @include fontsize(20);

                &:first-child {
                    margin-top: 60px;
                }
            }
        }
    }
}

.top_sec_ban {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 32px;
    padding-bottom: 96px;

    a {
        width: 14.55479452%;
        margin-right: 2.53%;

        img {
            width: 100%;
            height: auto;
        }

        &:nth-child(6n) {
            margin-right: 0;
        }

        &:nth-child(n + 7) {
            margin-top: 8px;
        }
    }

    @include res($res800) {
        justify-content: space-between;

        a {
            width: 30%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(n + 4) {
                margin-top: 8px;
            }
        }
    }
}

.main_box {
    position: relative;
    z-index: -2;
    background-color: $main_color;

    @include hack(11) {
        background-image: url("../img/top/top_back.png");
        background-attachment: fixed;
        background-size: contain;
        background-position: right bottom;
        background-repeat: no-repeat;

        #top_back {
            display: none;
        }
    }

    .main_box_wrap {
        position: relative;
    }

    .top_logo_back {
        // transition: all 0.2s;
        // transform: scaleY(0.1);
        // transform-origin: 50% 100%;
        // opacity: 0;
        bottom: 20px;
        left: 200px;
        z-index: -1;
        display: flex;
        align-items: flex-end;
        width: 148px;
        height: 139px;

        img {
            width: 100%;
            height: auto;
        }

        @include res(1800) {
            left: 100px;
        }

        @include res(1600) {
            left: 20px;
        }
    }

    .top_logo_back_abs {
        position: absolute;
    }

    .top_logo_back_fixed {
        position: fixed;
    }

    .top_logo_scale1 {
        opacity: 1;
        transform: scaleY(1);
    }

    .top_back {
        pointer-events: none;
        z-index: -2;
        content: "";
        position: relative;
        width: 100%;
        height: 100%;
        display: block;

        img {
            position: fixed;
            bottom: 0;
            left: 0;
        }
    }

    .top_back_abs {
        img {
            position: absolute;
        }
    }

    @include res($res500) {
        .main_box_wrap {
            padding-left: 0;
            padding-right: 0;
        }
    }
}