@charset "UTF-8";

.pr_maxWidth {
    max-width: 1200px;
    box-sizing: border-box;
    margin: auto;
}

.w200 {
    width: 100%;
    max-width: calc(200px + 20px);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;

    @include res($break) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.w400 {
    width: 100%;
    max-width: calc(400px + 20px);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;

    @include res($break) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.w500 {
    width: 100%;
    max-width: calc(500px + 20px);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;

    @include res($break) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.w700 {
    width: 100%;
    max-width: calc(700px + 20px);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;

    @include res($break) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.w800 {
    width: 100%;
    max-width: calc(800px + 20px);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;

    @include res($break) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.w1200 {
    max-width: 1232px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin: auto;

    @include res($break) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.br_sp {
    display: none;

    @include res($break) {
        display: block;
    }
}

.pr_pd16 {
    border-radius: 6px;
    background-color: #fff;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    @include res($break) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.pr_pd0 {
    @include res($break) {
        background-color: #fff;
        padding-left: 0;
        padding-right: 0;
    }
}

.pr_title_balloon {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    padding-top: 14px;
    padding-bottom: 18px;
    box-sizing: border-box;
    margin: auto;
    @include fontsize(30);
    line-height: 1;
    background-color: $main_color;
    border-radius: 3px;
    @include shadow1;

    &::after {
        position: absolute;
        bottom: -14px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-color: $main_color;
        transform: rotate(45deg);
    }
}

.pr_title_sun {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @include fontsize(18);
    text-align: left;
    background-color: $gray;
    color: #fff;
    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 40px;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 8px;

    a {
        color: #fff;
        display: block;
        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f0da";
            margin-left: 5px;
            display: inline-block;
            transition: all 0.2s;
        }
        &:hover {
             &::after {
                 transform: translateX(10px);
             }
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
        margin: auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $red;
        border: 4px solid #fff;
    }
}

.pr_trd_info {
    width: 15.06849315%;
    margin-right: 1.797945205%;
    transition: all 0.2s;

    a {
        display: block;

        div {
            position: relative;
            width: 100%;
            height: 130px;
            overflow: hidden;
            background-color: $gray_li;
            background-image: url("../img/top/slide_back2.png");

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: auto;
                opacity: 1;
            }
        }

        figcaption {
            position: relative;
            padding: 32px 8px;
            box-sizing: border-box;
            text-align: left;
            background-color: #f0f0f0;

            .pr_trd {
                position: absolute;
                top: 0;
                left: 0;
                width: 72px;
                @include fontsize(14);
                text-align: center;
                color: #fff;
                padding-top: 4px;
                padding-bottom: 4px;
                border-radius: 0;
            }

            .pr_sale {
                background-color: $red;
            }

            .pr_negotie {
                background-color: $yellow;
            }

            .pr_registrate {
                background-color: $blue;
            }
        }

        .pr_area {
            display: flex;
            width: 100%;
            @include fontsize(12);

            span {
                background-color: $green;
                width: 50%;
                text-align: center;
                padding-top: 3px;
                padding-bottom: 3px;
            }

            time {
                color: #fff;
                background-color: $gray;
                width: 50%;
                text-align: center;
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        transform: scale(1.01);
        @include shadow1;
    }

    @include res($res500) {
        a {
            figure {
                figcaption {
                    // min-height: 160px;
                    @include fontsize(14);
                }

                .pr_area {
                    display: block;

                    span {
                        display: block;
                        width: 100%;
                    }

                    time {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.pr_cat_img {
    position: relative;
    display: block;
    width: 12.5%;
    // width: 16.666666666%;
    // width: 20%;
    // width: 25%;
    overflow: hidden;
    background-color: #333;

    figure {
        position: relative;
        height: 72px;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;
            width: 100%;
            height: auto;
            transform: scale(1);
            transition: all 0.2s;
        }

        figcaption {
            @include fontsize(11);
            position: absolute;
            z-index: 1;
            bottom: 0;
            width: 100%;
            padding: 2px 4px;
            box-sizing: border-box;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: right;
            transition: all 0.3s;
        }

        &::after {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background-image: url("../img/top/slide_back2.png");
            background-color: rgba(0, 0, 0, 0.2);
            transition: all 0.2s;
        }
    }

    &:hover {
        figure {
            img {
                transform: scale(1.1);
                opacity: 1;
            }

            figcaption {
                bottom: -100%;
            }

            &::after {
                background: none;
            }
        }
    }

    @include res($break) {
        figure {
            img {
                width: 120%;
            }
        }
    }
}

.pr_product_under {
    overflow: hidden;
    display: block;
    height: 56px;

    figure {
        pointer-events: none;
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            transition: all 0.3s;
            transform: scale(1);
        }

        figcaption {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
            padding: 4px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.7);
            line-height: 1.1;
            @include fontsize(14);
            text-align: right;
            color: #fff;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background-image: url("../img/top/slide_back2.png");
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &:hover {
        figure {
            img {
                opacity: 1;
                transform: scale(1.1);
            }

            &::after {
                background-image: url("");
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }

    @include res($res500) {
        figure {
            figcaption {
                @include fontsize(10);
            }
        }
    }
}

.pr_list {
    border-bottom: 2px solid $gray;

    a {
        position: relative;
        display: flex;
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        box-sizing: border-box;

        time {
            white-space: nowrap;
        }

        p {
            padding-left: 24px;
            padding-right: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        i {
            @include fontsize(24);
            position: absolute;
            right: 16px;
            margin: auto;
            transform: rotate(0deg);
            transition: 0.2s;
        }
    }

    &:first-of-type {
        border-top: 2px solid $gray;
    }

    &:hover {
        a {
            background-color: lighten($main_color_li, 10%);

            i {
                transform: rotate(90deg);
            }
        }
    }

    @include res($break) {
        a {
            display: block;
            padding-top: 8px;
            padding-bottom: 8px;
            text-align: left;

            p {
                padding-left: 0;
            }

            i {
                display: flex;
                align-items: center;
                top: 0;
                bottom: 0;
            }
        }
    }
}

.ptn_imgLarge {
    a {
        display: block;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            transition: all 0.3s;

            &:hover {
                opacity: 1;
                transform: scale(1.05);
            }
        }
    }
}

.pr_imgLarge2 {
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        transition: all 0.3s;
        transform: scale(1);

        &:hover {
            opacity: 1;
            transform: scale(1.5);
        }
    }
}

.pr_underLine {
    a {
        position: relative;
        display: inline-block;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            margin: auto;
            width: 1px;
            height: 1px;
            display: inline-block;
            background-color: transparent;
            transition: all 0.3s;
        }

        &:hover {
            &::after {
                width: 100%;
                transform: scaleX(1);
                background-color: #333;
            }
        }
    }
}

.pr_submit {
    display: flex;
    justify-content: center;

    a,
    .pr_submit--back,
    input[type="submit"].pr_submit--back {
        border-radius: 6px;
        @include fontsize(18);
        color: #fff;
        width: auto;
        padding: 20px;
        box-sizing: border-box;
        background-color: $gray_li;
        margin-right: 8px;
        transition: all 0.3s;
    }

    a.back_mypage {
        width: auto;
    }

    input[type="submit"],
    .pr_submit--link {
        border-radius: 6px;
        width: 300px;
        padding: 20px;
        cursor: pointer;
        border: none;
        text-align: center;
        @include fontsize(18);
        background-color: $red;
        color: #fff;
        transition: all 0.3s;
    }

    input[type="submit"].pr_submit--confirm {
        background-color: $green;
    }

    // .pr_submit--back,
    // input[type="submit"].pr_submit--back {
    //     border-radius: 6px;
    //     @include fontsize(18);
    //     color: #fff;
    //     width: 140px;
    //     padding: 20px;
    //     box-sizing: border-box;
    //     background-color: $gray_li;
    //     margin-right: 8px;
    //     transition: all 0.3s;
    // }

    @include res($break) {
        flex-direction: column-reverse;

        a,
        .pr_submit--back,
        input[type="submit"].pr_submit--back,
        .pr_submit--link,
        input[type="submit"] {
            width: 100%;
            margin-top: 8px;
            margin-right: 0;
        }
    }
}

.pr_kochira {
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    margin-left: 1px;
    margin-right: 1px;
    background-color: $gray2;
    transition: all 0.3s;
}

.pr_cta {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 52px;
        padding: 10px;
        box-sizing: border-box;
        color: #fff;
        background-color: $green;
        border-radius: 3px;
        transition: all 0.2s;

        &:hover {
            background-color: darken($green, 10%);
        }
    }
}

.pr_selectBox {
    position: relative;

    select {
        border: none;
        border: 1px solid $gray_li;
        border-radius: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0dd";
        @include fontsize(24);
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 8px;
        pointer-events: none;
        color: $gray_li;
    }
}

.pr_selectBox2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    label {
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 6px;
        background-color: $gray2;
        position: relative;
        cursor: pointer;
    }

    input {
        display: none;
    }

    .form_text {
        padding-top: 5px;
        @include fontsize(10);
        text-align: left;
    }

    // &:last-child {
    //     margin-bottom: 60px
    // }
}

.pr_must {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 23px;
    border-radius: 6px;
    background-color: $red;
    @include fontsize(16);
    color: #fff;
}

.pr_arrowbtn {
    display: inline-block;
    @include fontsize(13);

    a {
        border-radius: 6px;
        padding: 5px;

        &.NEW {
            border: 1px solid $blue;
            color: $blue;
        }

        &.OLD {
            border: 1px solid $red;
            color: $red;
        }
    }
}

.error_msg_wrap {
    padding-bottom: 20px;
}

.error_msg {
    margin-top: 5px;
    margin-bottom: 10px;
    color: $red;
}

.pr_checkbox {
    input[type="checkbox"] {
        display: none;
        visibility: hidden !important;

        &+label {
            position: relative;
            transition: all 0.2s ease-out;
            display: flex;
            align-items: center;
            height: 50px;
            border-radius: 6px;
            background-color: $gray2;
            position: relative;
            cursor: pointer;
            padding: 10px;

            >span {
                width: 100%;
                display: block;
                text-align: left;
                padding-left: 75px;

                .checkbox_txt--on,
                .checkbox_txt--off {
                    margin-top: 3px;
                    display: block;
                    background-color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    @include m1plus;
                    font-weight: bold;
                }

                .checkbox_txt--on {
                    display: none;
                    color: $red;
                }
            }

            &::after,
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &::before {
                left: 12px;
                width: 54px;
                height: 20px;
                border-radius: 10px;
                background-color: #fff;
            }

            &::after {
                left: 14px;
                width: 16px;
                height: 16px;
                transition: all 0.2s ease-out;
                border-radius: 50%;
                background-color: $gray2;
            }
        }

        &:checked {
            &+label {
                background-color: #e5e3a3;

                .checkbox_txt--on {
                    display: block;
                }

                .checkbox_txt--off {
                    display: none;
                }

                &::after {
                    left: 48px;
                    background-color: #e5e3a3;
                }
            }
        }
    }

    input[type="reset"] {
        &+label {
            span {
                width: 100%;
                display: inline-block;
                text-align: center;
            }
        }
    }
}

.pr_radiobox {
    input[type="radio"] {
        visibility: hidden !important;
        display: none;

        &+label {
            position: relative;
            transition: all 0.2s ease-out;
            display: flex;
            align-items: center;
            height: 50px;
            border-radius: 6px;
            background-color: $gray2;
            position: relative;
            cursor: pointer;
            padding: 10px;

            >span {
                display: inline-block;
                text-align: left;
                padding-left: 40px;
            }

            &::after,
            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                box-sizing: border-box;
                content: "";
            }

            &::before {
                left: 10px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #fff;
            }

            &::after {
                left: 12px;
                width: 16px;
                height: 16px;
                transition: all 0.2s ease-out;
                border-radius: 50%;
                background-color: #fff;
            }
        }

        &:checked {
            &+label {
                background-color: $red;
                color: #fff;

                &::after {
                    background-color: $red;
                }
            }
        }
    }
}

.pr_radiobox2 {
    align-items: center;
    position: relative;
    display: inline-flex;

    input[type="radio"] {
        visibility: hidden !important;
        display: none;

        &+label {
            cursor: pointer;
            margin-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 30px;

            &::after,
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }

            &::before {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $gray2;
                transition: all 0.2s;
            }

            &::after {
                width: 10px;
                height: 10px;
                left: 6px;
                border-radius: 50%;
            }
        }

        &:checked {
            &+label {
                // &::before {
                //     background-color: $blue;
                // }

                &::after {
                    background-color: $blue;
                }
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}


.pr_checkbox2 {
    input[type="checkbox"] {
        visibility: hidden !important;

        &+label {
            position: relative;
            transition: all 0.2s ease-out;

            span {
                display: flex;
                align-items: center;
                text-align: left;
                padding-left: 50px;
                padding-right: 0 !important;
            }

            &::after,
            &::before {
                top: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
                box-sizing: border-box;
                content: "";
            }

            &::before {
                left: 12px;
                width: 20px;
                height: 20px;
                border-radius: 6px;
                background-color: #fff;
            }

            &::after {
                top: -5px;
                left: 19px;
                width: 7px;
                height: 14px;
                transform: rotate(0deg);
                border-bottom: 3px solid transparent;
                border-right: 3px solid transparent;
            }
        }

        &:checked {
            &+label {
                background-color: #e5e3a3;

                &::after {
                    transform: rotate(40deg);
                    border-bottom: 3px solid #c86d6b;
                    border-right: 3px solid #c86d6b;
                }
            }
        }
    }

    input[type="reset"] {
        &+label {
            span {
                width: 100%;
                display: inline-block;
                text-align: center;
            }
        }
    }
}

.pr_checkbox3 {
    position: relative;
    display: inline-flex;
    white-space: nowrap;
    margin-right: 10px;

    input[type="checkbox"] {
        visibility: hidden !important;
        display: none;

        &+label {
            cursor: pointer;

            span {
                padding-left: 25px;
            }

            &::after,
            &::before {
                content: "";
                position: absolute;
            }

            &::before {
                top: 2px;
                width: 20px;
                height: 20px;
                border-radius: 6px;
                background-color: $gray2;
                transition: all 0.2s;
            }

            &::after {
                top: 5px;
                left: 7px;
                width: 3px;
                height: 8px;
            }
        }

        &:checked {
            &+label {
                &::after {
                    transform: rotate(40deg);
                    border-bottom: 3px solid #fff;
                    border-right: 3px solid #fff;
                }

                &::before {
                    background-color: $blue;
                }
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.carousel_arrow {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    color: $gray;

    &::before {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        @include fontsize(50);
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }

    &[aria-label="Previous"] {
        left: 0;

        &::before {
            content: "\f104";
        }
    }

    &[aria-label="Next"] {
        right: 0;

        &::before {
            content: "\f105";
        }
    }

    &.slick-disabled {
        color: $gray2;
        pointer-events: none;
    }
}