@charset "UTF-8";

.bbsnext {
    width: 300px;
    margin: auto;
    margin-bottom: 50px;

    a {
        border-radius: 6px;
        display: block;
        color: #fff;
        padding: 10px;
        background-color: darken($green, 10%);
    }

    @include res($break) {
        width: 100%;
    }
}

.regist_main .bbsbox {
    margin-bottom: 100px;
}

.bbsbox_title {
    border-bottom: 1px solid $gray2;
    @include fontsize(16);
    text-align: left;
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    &--title {
        font-weight: bold;
    }

    &--time {
        margin-left: 5px;
        @include fontsize(14);
    }

    &--name {
        display: block;
        margin-left: 10px;
        text-align: right;

        a {
            display: inline-block;
            padding: 2px 6px;
            color: #fff;
            border-radius: 6px;
            background-color: $red;
            @include fontsize(14);
        }
    }

    @include res($res800) {
        display: block;

        &--wrap {
            @include fontsize(14);

            &:first-child {
                display: block;
                margin-bottom: 5px;

                span {
                    display: block;
                }
            }

        }
    }

    @include res($break) {
        &--toukousya {
            display: none;
        }
    }
}

.bbsbox_text {
    display: flex;

    &--left {
        width: 300px;
        border-radius: 6px;
    }

    &--right {
        flex: 1 1 0%;
        text-align: justify;
        margin-left: 20px;
    }

    @include res($res800) {
        &--left {
            width: 200px;
            border-radius: 6px;
        }
    }

    @include res($break) {
        display: block;


        &--left {
            width: 100%;
            margin-bottom: 5px;
        }

        &--right {
            margin-left: 0;
        }


    }
}