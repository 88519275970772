@charset "UTF-8";

.un_sec_mdl,
.un_sec_shp,
.un_sec_info {
    margin-bottom: 16px;

    .pr_title_sun {
        margin-bottom: 0;
    }

    .pr_list {
        a {
            flex-direction: column;
            text-align: left;

            p {
                padding-left: 0;
            }

            i {
                top: 34%;
            }
        }

        &:first-of-type {
            border-top: 0;
        }
    }
}

.mypage_sidebar {
    border-radius: 6px;
    overflow: hidden;

    i {
        color: $yellow;
        margin-right: 2px;
    }

    >ul {
        >li {
            border-bottom: 1px solid $gray2;
        }
    }

    dt,
    dd {
        position: relative;
        text-align: left;
        display: block;
        height: 48px;
        background-color: #fff;
    }

    dt {
        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 16px;
        }
    }

    dd {
        a {
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 30px;
            background-color: darken(#fff, 5%);
        }
    }

    a {
        &::after {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin: auto;
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }

    .pr_togglePluse {
        a {
            &::after {
                content: none;
            }
        }
    }

    .current {
        position: relative;

        dt {
            border-bottom: 5px solid $red;
        }

        >dd {
            display: block;
        }
    }
    @include res($break) {
        border-top: 1px solid #ddd;
        border-radius: 0;
    }
}