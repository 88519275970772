@charset "UTF-8";

#pull_down {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .pull_down_menu {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        width: 100%;
        z-index: 50;
        > ul {
            display: block;
            display: flex;
            flex-direction: column;
            background-color: $main_color_li;
            li {
                border-left: 0;
                border-right: 0;
                width: 100%;
                border-top: 1px solid lighten($main_color_li, 10%);
                a {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    background-color: lighten($main_color_li, 10%);
                }
            }
        }
    }
    &:active {
        background-color: lighten($main_color_li, 10%);
    }

    @include res($break) {
        padding-top: 6px;
        padding-bottom: 6px;
        .pull_down_menu {
            > ul {
                flex-direction: row;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    border-right: 1px solid lighten($main_color_li, 10%);
                    a {
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                    &:nth-child(2n) {
                        border-right: 0;
                    }
                }
            }
        }
    }
}

.hed_main_title {
    background-color: #fff;
    padding-bottom: 12px;
    .sub_title {
        background-color: $gray;
        color: #fff;
        @include fontsize(12);
        padding-top: 3px;
        padding-bottom: 3px;
        margin-bottom: 10px;
    }
    a {
        display: block;
        width: 216px;
        margin: 0 auto;
    }
}

.hed_nav {
    background-color: $main_color;
    transition: all 0.1s;
    > ul {
        display: flex;
        justify-content: space-between;

        li {
            width: 25%;
            border-right: 1px solid $main_color_li;
            box-sizing: border-box;
            @include fontsize(20);

            a {
                display: block;
                padding-top: 35px;
                padding-bottom: 30px;
            }

            &:first-child {
                border-left: 1px solid $main_color_li;
            }
            &:hover {
                background-color: $main_color_li;
            }
        }
    }
    @include res($res900) {
        ul {
            li {
                @include fontsize(14);
            }
        }
    }
}

.hed_search {
    background-color: $gray;

    div {
        display: flex;
        justify-content: space-between;
    }

    form {
        position: relative;
        display: flex;
        width: auto;
        flex: 1 auto;

        input[type="text"],
        select {
            @include fontsize(18);
            padding-left: 8px;
            box-sizing: border-box;
        }

        div {
            position: relative;
            width: 29%;
            margin-right: 8px;

            select {
                color: $gray_li;
                width: 100%;
                background: transparent;
                border: none;
                border-bottom: 2px solid $gray_li;
                margin-top: 8px;
                margin-bottom: 8px;
                border-radius: 0;
                -webkit-appearance: none;
                &.BLACK {
                    color: #333;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &::after {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f0dd";
                @include fontsize(24);
                display: block;
                position: absolute;
                top: 5px;
                right: 8px;
                pointer-events: none;
                color: $gray_li;
            }
        }

        input[type="text"] {
            flex: 1 auto;
            padding-right: 48px;
            box-sizing: border-box;
            margin-right: 8px;
            color: $gray_li;
            background: transparent;
            border: none;
            border-bottom: 2px solid $gray_li;
            margin-top: 8px;
            margin-bottom: 8px;
            @include res($break) {
                width: 100%;
            }
        }

        input[type="submit"] {
            @include fontsize(27);
            color: $gray_li;
            font-family: "Font Awesome 5 Free";
            position: absolute;
            top: 0;
            bottom: 8px;
            margin: auto;
            right: 8px;
            border: 0;
            z-index: 10;
            background-color: transparent;
            transform: translateY(0);
            transition: 0.2s;

            &:hover {
                cursor: pointer;
                transform: translateY(-2px);
            }
        }
    }

    ul {
        display: flex;
        justify-content: flex-end;
        width: 33.333333333%;

        li {
            border-right: 1px solid #a9a9a9;

            a {
                @include fontsize(18);
                color: #fff;
                display: block;
                padding: 15px 8px;
                height: 100%;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                box-sizing: border-box;

                &:hover {
                    background-color: #a9a9a9;
                }
            }

            &:first-child {
                border-left: 1px solid #a9a9a9;
            }
        }

        .hed_maypage {
            width: 70%;
        }

        .hed_log {
            width: 30%;

            a {
                @include fontsize(16);
                i {
                    padding-right: 2px;
                }
            }
        }
    }
    @include res(1160) {
        ul {
            width: 40%;
            li {
                a {
                    @include fontsize(14);
                }
            }
            .hed_log {
                a {
                    @include fontsize(14);
                }
            }
        }
    }
    @include res($res900) {
        form {
            select {
                @include fontsize(13);
            }
        }
        ul {
            width: 50%;
        }
    }
    @include res($break) {
        ul {
            width: 100%;
            .hed_log {
                width: 100%;
            }
        }
    }
}

// スクロール時の追従スタイル
.hed_scroll {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    @include shadow2;

    .hed_nav {
        background-color: rgba(243, 186, 86, 0.95);
        ul {
            li {
                @include fontsize(16);

                a {
                    padding-top: 10px;
                    padding-bottom: 15px;
                }
            }
        }
    }

    .hed_search {
        background-color: rgba(67, 67, 67, 0.95);
        form {
            input[type="text"],
            select {
                @include fontsize(16);
            }

            div {
                select {
                    margin-top: 4px;
                }

                &::after {
                    @include fontsize(24);
                    top: -1px;
                }
            }

            input[type="text"] {
                margin-top: 4px;
            }

            input[type="submit"] {
                @include fontsize(20);
            }
        }
        ul {
            li {
                a {
                    @include fontsize(16);
                    padding: 10px 8px;
                }
            }

            .hed_log {
                width: 30%;

                a {
                    @include fontsize(16);
                }
            }
        }
    }

    @include res($res900) {
        .hed_nav {
            background-color: rgba(243, 186, 86, 0.95);
            ul {
                li {
                    @include fontsize(14);
                }
            }
        }
    }
}

#mainButton,
.hedBox_close {
    display: none;
}

@include res($break) {
    header {
        .hed_nav_mobile {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 100;
            width: 100%;
            transition: all 0.2s;
        }

        .hed_nav_mob {
            background-color: rgba(243, 186, 86, 0.95);

            ul {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;

                li {
                    width: 100%;
                    border-right: 0;
                    border-bottom: 1px solid $main_color_li;
                    box-sizing: border-box;

                    a {
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }

                    &:first-child {
                        border-left: 0;
                    }
                }
            }
        }

        .hed_search_mob {
            background-color: rgba(67, 67, 67, 0.95);
            padding-top: 118px;
            padding-bottom: 16px;

            > div {
                display: flex;
                flex-direction: column-reverse;

                form {
                    flex-direction: column;

                    > div {
                        width: 100%;

                        select {
                            margin-top: 8px;
                            padding-bottom: 2px;
                            @include fontsize(14);
                        }

                        &::after {
                            top: -3px;
                        }
                    }

                    input[type="text"] {
                        margin-top: 0;
                        padding-top: 13px;
                        margin-right: 0;
                        @include fontsize(14);
                    }

                    input[type="submit"] {
                        top: 39px;
                        right: -6px;
                    }
                }

                ul {
                    flex-direction: column;

                    .hed_log,
                    .hed_maypage {
                        width: 100%;
                        border-top: 1px solid $gray_li;

                        a {
                            @include fontsize(12);
                            padding: 8px;
                        }
                    }

                    .hed_log {
                        border-left: 1px solid $gray_li;
                        border-bottom: 1px solid $gray_li;
                        margin-bottom: 8px;
                    }
                }
            }
        }

        #mainButton {
            display: block;
            position: fixed;
            top: 30px;
            right: 10px;
            z-index: 9999;
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 8px;
            border: 3px solid $main_color;
            background-color: #fff;
            border-radius: 3px;
            @include shadow1;

            .hum_borfer {
                display: block;
                width: 100%;
                width: 40px;
                height: 28px;
                position: relative;
                z-index: 999;

                i {
                    display: block;
                    width: 100%;
                    height: 4px;
                    background-color: $main_color;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .lastchild {
                    display: inline-block;
                    padding-top: 5px;
                    padding-bottom: 36px;
                }

                &::after,
                &::before {
                    content: "";
                    display: block;
                    background-color: $main_color;
                    width: 100%;
                    height: 4px;
                    transition: 0.2s;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            .close {
                i {
                    display: none;
                }

                &::before {
                    top: 12px;
                    transform: rotate(-45deg);
                    transition: 0.2s;
                }

                &::after {
                    bottom: 12px;
                    transform: rotate(-135deg);
                    transition: 0.2s;
                }
            }
        }

        // ハンバーガーメニュークローズcss
        .menu_close {
            top: -200%;
        }
    }
}

.mypageNav {
    display: none;
    @include res($break) {
        display: block;
    }
    &._top {
        background-color: $gray;
        .subpageHeader_wrap {
            background-color: #fff;
            a {
            }
        }
    }
    .subpageHeader_login {
        padding-top: 10px;
        padding-bottom: 10px;
        li {
            border-bottom: 0;
        }
        .subpageHeader_id {
            display: block;
            color: #fff;
            a {
                background-color: #fff;
                border-radius: 6px;
            }

            .subpageHeader_id--change {
                justify-content: flex-end;
            }
        }
        .subpageHeader_logout--btn {
            justify-content: center;
        }
    }
}

// 下層ページヘッダー

.subpageHeader {
    background-color: #fff;
    .subpageNav {
        @include res($break) {
            overflow: scroll;
            transition: all 0.5s;
            pointer-events: none;
            z-index: 999;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            opacity: 0;
            transform: scale(1.1);
            &.is_active {
                pointer-events: auto;
                opacity: 100;
                transform: scale(1);
            }
        }
    }
}

.subpageHeader_wrap {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .subpageHeader_title {
        width: 240px;
        a {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    @include res($res900) {
        .subpageHeader_title {
            width: 25%;
        }
    }
    @include res($break) {
        display: flex;
        justify-content: center;
        height: 57px;
        .subpageHeader_title {
            width: 120px;
        }
    }
}

.subpageHeader_info {
    display: flex;
    align-items: center;
    @include res($break) {
        display: none;
    }
}

.subpageHeader_login,
.subpageHeader_acountchange {
    display: flex;
    justify-content: flex-end;
}
.subpageHeader_login {
    li {
        display: flex;
        align-items: center;
        border-right: 2px solid $gray2;
        padding: 5px 10px;
        &.subpageHeader_id {
            .subpageHeader_id--color {
                color: $green;
                font-weight: bold;
                margin-right: 3px;
                &::before {
                    margin-right: 3px;
                    content: "\f2bd";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }
            .subpageHeader_id--change {
                display: flex;
                align-items: center;
                i {
                    margin-left: 10px;
                    margin-right: 5px;
                }
                input {
                    border-radius: 6px;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    font-weight: bold;
                    &.subpageHeader_id--tanaka {
                        background-color: $blue;
                    }
                    &.subpageHeader_id--mweave {
                        background-color: $red;
                    }
                    &.subpageHeader_id--kientai {
                        background-color: $yellow;
                    }
                }
            }
        }
        &.subpageHeader_logout--btn {
            button {
                background-color: $gray2;
                border-radius: 6px;
                padding: 5px;
                &::before {
                    margin-right: 3px;
                    content: "\f2f5";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }
        }
    }
}

.subpageNav {
    ul {
        li {
            a,
            dt {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 66px;
                @include fontsize(14);
            }
        }
    }
    .subLeft_nav {
        border-radius: 0;
    }
}

/* ------------------------------------------------------------------
  menu_trigger
------------------------------------------------------------------ */

.menu_trigger,
.menu_trigger span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
}

.menu_trigger {
    z-index: 1000;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    width: 57px;
    height: 57px;
    cursor: pointer;
    background-color: $main_color;

    span {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
        height: 4px;
        background-color: #fff;
        border-radius: 4px;
        &:nth-of-type(1) {
            top: 10px;
        }
        &:nth-of-type(2) {
            top: 19px;
        }
        &:nth-of-type(3) {
            bottom: 25px;
        }
    }
    p {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        padding-bottom: 5px;
        @include m1plus;
        @include fontsize(10);
        font-weight: bold;
        text-align: center;
    }
    //is-active
    &.is_active {
        background-color: $green;
        span {
            &:nth-of-type(1) {
                top: 0;
                transform: translateY(20px) rotate(-315deg);
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                bottom: 13px;
                transform: translateY(-20px) rotate(315deg);
            }
        }
    }
}

html.stop_scroll,
body.stop_scroll {
    overflow: hidden;
}
@media only screen and (max-width: 767px) {
    .menu_trigger {
        display: block;
    }
}
