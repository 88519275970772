@charset "UTF-8";

.pr_area_title {
    margin-top: 24px;
    @include fontsize(14);
    width: 100%;
    padding: 8px;
    text-align: left;
    background-color: $gray_li;
    border-left: 20px solid $gray;
    box-sizing: 8px;
    box-sizing: border-box;
    &:first-of-type {
        margin-top: 0;
    }
}

.shop_list_search {
    .type_box {
        li {
            width: 32%;
        }
    }
    @include res($res500) {
        .type_box {
            li {
                a {
                    @include fontsize(12);
                }
            }
        }
    }
}

.shop_tab_mypagele {
    .shop_name_box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        .shop_title {
            text-align: left;
            width: 100%;
            @include fontsize(16);
            font-weight: bold;
            padding: 8px;
            margin-bottom: 8px;
            box-sizing: border-box;
            border-bottom: 1px solid $gray;
        }
    }
    .shop_img_box {
        position: relative;
        width: 20%;
        height: 137px;
        overflow: hidden;
        img {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: auto;
        }
    }
    .shop_type_box {
        flex-direction: column;
        width: 20%;
        .regi_type {
            background-color: $red;
        }
        .mainte_type {
            background-color: $green;
        }
        span {
            width: 80%;
            color: #fff;
            text-align: center;
            display: block;
            border-radius: 3px;
            padding: 4px;
            box-sizing: border-box;
            &:last-child {
                margin-top: 8px;
            }
        }
    }
    .shop_area1_box {
        width: 10%;
    }
    .shop_area2_box {
        width: 30%;
    }
    .shop_tell_box {
        width: 20%;
    }
    .shop_tex_box {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid $gray;
        width: 100%;
        line-height: 1.6;
        @include beta;
    }
    .list_wrap {
        .pr_list_link {
            padding: 0 8px 8px 8px;
            box-sizing: border-box;
            .pr_tab_mypagele {
                flex-wrap: wrap;
            }
        }
    }
    @include res($break) {
        .list_wrap {
            a {
                .shop_img_box {
                    padding: 0;
                }
                .shop_type_box {
                    padding: 8px !important;
                }
                .shop_tex_box {
                    display: none;
                }
            }
        }
    }
}

.shop_detail_shop {
    img {
        width: 100%;
        height: auto;
    }
    > div {
        margin-bottom: 8px;
    }

    .pc_box {
        width: 100%;
        display: flex;
        justify-content: space-between;

        figure {
            width: 31%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .mobile_box {
        display: none;
    }
    @include res($break) {
        .pc_box {
            display: none;
        }

        .mobile_box {
            display: block;
            width: 100%;

            figure {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
