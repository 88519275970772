@charset "UTF-8";

.regist_main {
    section {
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .toggle_title {
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }
}


// .regist_bk {
//     background-image: url("/img/common/stamp_old.svg");
//     background-size: 50px;
//     background-position: right 10px top 30px;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
// }

.regist_display {
    .display_wrap {
        @include fontsize(15);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            width: 24%;

            label {
                height: auto;
            }
        }

        .display_txt {
            padding-top: 3px;
            @include fontsize(10);
            text-align: left;
        }
    }

    @include res($res1200) {
        .display_wrap {
            @include fontsize(14);
        }
    }

    @include res($res1100) {
        .display_wrap {
            @include fontsize(13);

            li {
                width: 49%;

                &:nth-child(-n + 2) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    @include res($break) {
        .display_wrap {
            @include fontsize(12);

            .pr_checkbox {
                input[type="checkbox"] {
                    &+label {
                        >span {
                            white-space: nowrap;
                            padding-left: 65px;
                        }
                    }
                }
            }
        }
    }
}

.regist_product {
    .product_barcode {
        justify-content: space-between;

        input[type="text"] {
            width: 83.857442348%;
        }

        button {
            width: 14.0461215933%;
        }
    }

    .product_type {
        justify-content: space-between;

        .pr_selectArrow {
            flex: 1 1 0%;
        }

        input[type="text"] {
            width: 230px;
            margin-left: 10px;
        }

        select {
            border: none;
            height: 100%;
        }
    }

    .product_maker {
        justify-content: space-between;

        .pr_selectArrow {
            width: 100%;
        }

        input[type="text"] {
            width: 32.9140461216%;
        }

        button {
            width: 14.0461215933%;
        }

        select {
            border: none;
            height: 100%;
        }
    }

    .product_num {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 50px;
        height: 100%;
        line-height: 1.1;
        @include fontsize(13);
    }

    @include res($break) {
        .product_type {
            height: auto;
            flex-direction: column;

            select {
                height: 40px;
                margin-bottom: 5px;
            }

            input[type="text"] {
                margin-left: 0;
                width: 100%;
                height: 40px;
            }
        }
    }
}

.regist_joutai,
.regist_kudou {

    .joutai_wrap,
    .kudou_wrap {
        display: flex;
        justify-content: space-between;
    }

    li {
        width: 19%;
    }

    @include res($res1100) {

        .kudou_wrap,
        .joutai_wrap {
            flex-wrap: wrap;
        }

        li {
            width: 49.2%;
            @include fontsize(14);
            margin-bottom: 5px;
        }
    }
}

.regist_joutai {
    .joutai_wrap {
        li {
            width: 32.352941176%;
        }
    }

    @include res($break) {
        .joutai_wrap {
            li {
                width: 100%;
            }
        }
    }
}

.regist_seibi {
    .seibi_wrap {
        display: flex;
        justify-content: space-between;

        ul {
            width: 49.2%;

            li {
                width: 100%;
            }

            .seibi_radiobox {
                position: relative;
                display: flex;
                justify-content: space-between;
                background-color: #8f3432;
                border-radius: 6px;
                padding: 10px;
                box-sizing: border-box;

                p {
                    width: 48%;
                }

                &::before {
                    position: absolute;
                    top: -20px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #8f3432;
                    border-left: 10px solid transparent;
                }
            }
        }

        .seibi_miseibi {
            display: flex;
            flex-direction: column;
        }
    }

    @include res($res1100) {

        .seibi_wrap,
        .joutai_wrap {
            display: block;

            ul {
                width: 100%;

                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.regist_price {
    .regist_price--list {

        .price_check,
        .price_general,
        .price_gyohan {
            padding: 0;
            background-color: transparent;

            p {
                width: 100%;
                height: 100%;
                margin-right: 10px;

                label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    box-sizing: border-box;

                    >span {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        padding-right: 20px;
                    }

                    .form_spell {
                        align-items: center;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .price_check {
            display: block;

            .price_checkbox_container {
                display: flex;
                justify-content: space-between;
                @include fontsize(13);

                p {
                    height: auto;
                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.upper {
                    p {
                        width: 24%;
                    }
                }

                &.lower {
                    p {
                        width: 100%;
                    }
                }

                &:first-child {
                    margin-bottom: 3px;
                }
            }
        }

        .price_oudan {
            flex: none;
            padding: 0;
            width: auto;
        }
    }

    .pr_radiobox {
        input[type="radio"]+label {
            height: 100%;
        }
    }

    .r_baiyaku {
        &:checked {
            background-color: $red;
        }
    }

    .heightauto {
        height: auto;
    }

    .price_hanbaistate {
        background-color: initial;
        padding: 0;

        .price_radio_baiyaku,
        .price_radio_syodan {
            width: 49.5%;
            height: 100%;

   
            label {
                height: 100%;
            }
        }
    }

    .regist_price--teika dd {
        border-radius: 6px;
    }

    @include res($res1100) {
        .regist_price--list {
            margin-bottom: 20px;
              
            .price_oudan {
                margin-bottom: 20px;
            }
        }

        .pr_registList {
            dt {
                margin-bottom: 5px;
                border-radius: 5px;
            }
        }

        .price_general,
        .price_gyohan {
            margin-bottom: 5px;
        }

        .price_oudan {
            float: right;


            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .price_oudan,
        .price_oudan .pr_radiobox {
            label {
                margin-left: 0;
                width: 100%;
            }

            &.price_radio {
                width: 100px;
            }

            &.price_oudan {
                margin-top: 0;
                width: 100px;
            }
        }

        .pr_formList {
            margin-bottom: 30px;
        }

        .price_checkbox_container {
            flex-wrap: wrap;

            &.upper {
                margin-bottom: 0 !important;

                p {
                    width: 49.5% !important;
                    margin-right: 0;

                    &:nth-child(n - 2) {
                        margin-bottom: 5px;
                    }
                }
            }

            &.lower {
                p {
                    margin-right: 0;

                    &:first-child {
                        margin-bottom: 5px;
                    }
                }



            }
        }

        .price_check {
            height: auto !important;
        }
    }
}

.regist_send {
    .form_send {
        display: flex;
        flex-direction: column;

        li {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .form_send_mitumori,
        .form_send_tentou {
            label {
                text-align: center;

                span {
                    display: block;
                    text-align: center;
                }
            }
        }

        .form_send_price {
            display: flex;
            justify-content: space-between;

            p {
                label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    box-sizing: border-box;

                    .form_send_text {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;
                        padding-right: 20px;
                        padding-top: 0;

                        input {
                            display: block;
                            width: 70%;
                            height: 100%;
                            border: 0;
                        }

                        .form_yen {
                            display: flex;
                            align-items: flex-end;
                            height: 100%;
                            padding-left: 10px;
                        }
                    }
                }

                width: 100%;

                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }

    @include res($break) {
        .form_send {
            .form_send_price {
                display: block;

                p {
                    &:first-child {
                        margin-bottom: 5px;
                    }

                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.regist_img {
    .regist_imgBox {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-right: 20px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 6px;
            overflow: hidden;
        }

        .regist-slick_img {
            position: relative;
            width: 300px;
            margin-right: 10px;

            &::before {
                content: "";
                display: block;
                padding-top: 100%;
            }
        }

        .regist_img--num {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            background-color: #a6a6a6;
            color: #fff;
            width: 25%;
            border-radius: 6px 0 6px 0;
            font-weight: bold;
        }
    }

    @include res($break) {
        ul {
            display: block;

            .form_img1 {
                width: 100%;
                margin-bottom: 5px;
            }

            .form_img2 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 5px;

                .form_img2_1 {
                    margin-bottom: 0;
                    width: 49.5%;
                }

                .form_img2_2 {
                    width: 49.5%;
                }
            }

            .form_img3 {
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                div {
                    width: 49.5%;

                    &:nth-child(3),
                    &:nth-child(4) {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

.regist_spec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form_title {
        width: 100%;
    }

    dl {
        width: 100%;
    }

    .regist_spec--bariki {
        width: 48%;
    }

    .regist_spec--meter {
        width: 50%;
    }

    .regist_spec--size {
        dd {
            align-items: center;

            span {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    .pr_selectArrow {
        width: 100px;
        margin-left: 5px;

        select {
            border: none;
            height: 100%;
        }
    }

    @include res($break) {
        dl {
            width: 100% !important;
        }
    }
}

.regist_movie {
    dl {
        width: 100%;
    }
}

.regist_description {
    textarea {
        @include fontsize(20);
        border-radius: 6px;
        border: 1px solid #a6a6a6;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        height: 500px;
    }
}

.regist_index_submit {
    display: flex;
    justify-content: space-between;

    input {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        cursor: pointer;
        color: #fff;
        height: 60px;
        @include fontsize(20);

        &:first-child {
            background-color: #a6a6a6;
            width: 35.2941176471%;
        }

        &:last-child {
            background-color: #a2cea9;
            width: 63.2352941176%;
        }
    }
}

.regist_done--submit {
    margin-top: 40px;
}

.regist_done--2 {
    flex-wrap: wrap;
    .regist_done--mypage {
        width: 100%;
        margin-bottom: 10px;
    }
    @include res($break) {
        flex-direction: column;
    }
}

.regist_done--new {
    background-color: $red + !important;
}
.regist_done--old {
    background-color: $blue + !important;
}

//** edit.php ********************************************************** //


.edit_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pr_edit_img {
    width: 33%;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;


    iframe {

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 6px;
        overflow: hidden;
    }

    .edit_img {
        border-radius: 6px;
        background-color: $gray;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

    }

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.edit_seibi {
    display: flex;
    justify-content: space-between;

    li {
        width: 32%;
    }
}

.edit_img .thickbox_wrap {
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.7);
}





.pr_edit_img .edit_img .thickbox_wrap a {
    font-size: 20px;
    font-size: 1.25rem;
    -webkit-filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.6));
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.6));
    padding: 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.pr_edit_img .edit_img .thickbox_wrap .thickbox_lft {
    color: #4fb067;
}

.pr_edit_img .edit_img .thickbox_wrap .thickbox_rev {
    color: #6475df;
}

.pr_edit_img .edit_img .thickbox_wrap .thickbox_rgh {
    color: #4fb067;
}

.pr_edit_img .edit_img .thickbox_wrap .thickbox_del {
    color: #b04f4f;
}

.pr_edit_img .edit_img::before {
    position: absolute;
    top: 0;
}

@media only screen and (max-width: 767px) {
    .pr_edit_img {
        width: 49% !important;
    }
}