@charset "UTF-8";

.movielist_wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
}

.movielist_box {
    @include fontsize(13);
    cursor: pointer;
    padding: 5px;
    margin-bottom: 10px;
    width: 20%;
    text-align: left;
    transition: all 0.2s;
    background-color: #fff;
    &--img {
        border: 2px solid $gray2;
        margin-bottom: 5px;
    }
    &--tit {
        margin-bottom: 5px;
    }
    &--cat {
        border-bottom: 2px solid $orange;
        text-align: center;
        margin-bottom: 5px;
    }
    &--maker {
        border-bottom: 2px solid $orange;
        text-align: center;
        margin-bottom: 5px;
    }
    &--user {
        border-bottom: 2px solid $orange;
        text-align: center;
        margin-bottom: 5px;
    }
    &--url {
        @include fontsize(10);
        margin-bottom: 5px;
    }
    &--day {
        @include fontsize(10);
        margin-bottom: 5px;
    }
    &:hover {
    filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
    transform: scale(1.01);
    margin-top: 8px;

    }
    @include res($break) {
        width: 49%;
    }
}
