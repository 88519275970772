@charset "UTF-8";
@import "settings/value";
@import "settings/reset";

//jq
@import "thirdparty/fakeLoader";
@import "thirdparty/lightbox";
@import "thirdparty/slick";
@import "thirdparty/slick-theme";
@import "thirdparty/thirdparty";

// pr
@import "parts/fontsize";
@import "parts/pr";
@import "parts/pr_subpage";
@import "parts/br";

//topレイアウト
@import "layout/header";
@import "layout/top";
@import "layout/index";
@import "layout/footer";
@import "layout/sidebar";

//下層ページ
//menu
@import "layout/product";
@import "layout/shop";
@import "layout_subpage/subpage/regist";
@import "layout_subpage/subpage/movie";

//mypage
@import "layout_subpage/mypage/mypage";
@import "layout_subpage/mypage/mypage_iframe";

@import "layout_subpage/mypage/login";
@import "layout_subpage/mypage/regist";
@import "layout_subpage/mypage/movie";
@import "layout_subpage/mypage/orico";
@import "layout_subpage/mypage/change";
@import "layout_subpage/mypage/maker";
@import "layout_subpage/mypage/shop_list";
@import "layout_subpage/mypage/data";
@import "layout_subpage/mypage/bbs";
@import "layout_subpage/mypage/tools";
@import "layout_subpage/mypage/ajaxup2";

//function
@import "layout/create";