@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('../font/ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('../font/slick.eot');
    src: url('../font/slick.eot?#iefix') format('embedded-opentype'), url('../font/slick.woff') format('woff'), url('../font/slick.ttf') format('truetype'), url('../font/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    // width: 20px;
    // height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;

    z-index: 1;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 25px;
    line-height: 1;

    opacity: .75;
    color: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 2px;
}
[dir='rtl'] .slick-prev
{
    right: 25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: 2px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: 25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}




// @charset "UTF-8";
//
// // Default Variables
//
// // Slick icon entity codes outputs the following
// // "\2190" outputs ascii character "←"
// // "\2192" outputs ascii character "→"
// // "\2022" outputs ascii character "•"
//
// $slick-font-path: "./font/" !default;
// $slick-font-family: "slick" !default;
// $slick-loader-path: "./" !default;
// $slick-arrow-color: white !default;
// $slick-dot-color: black !default;
// $slick-dot-color-active: $slick-dot-color !default;
// $slick-prev-character: "\2190" !default;
// $slick-next-character: "\2192" !default;
// $slick-dot-character: "\2022" !default;
// $slick-dot-size: 6px !default;
// $slick-opacity-default: 0.75 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 0.25 !default;
//
// @function slick-image-url($url) {
//     @if function-exists(image-url) {
//         @return image-url($url);
//     }
//     @else {
//         @return url($slick-loader-path + $url);
//     }
// }
//
// @function slick-font-url($url) {
//     @if function-exists(font-url) {
//         @return font-url($url);
//     }
//     @else {
//         @return url($slick-font-path + $url);
//     }
// }
//
// /* Slider */
//
// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("../font/ajax-loader.gif") center center no-repeat;
//     }
// }
//
// /* Icons */
// @if $slick-font-family == "slick" {
//     @font-face {
//         font-family: "../font/slick";
//         src: slick-font-url("../font/slick.eot");
//         src: slick-font-url("../font/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("../font/slick.woff") format("../font/woff"), slick-font-url("../font/slick.ttf") format("truetype"), slick-font-url("../font/slick.svg#slick") format("svg");
//         font-weight: normal;
//         font-style: normal;
//     }
// }
//
// /* Arrows */
//
// .slick-prev,
// .slick-next {
//   z-index: 1;
//     position: absolute;
//     display: block;
//     height: 20px;
//     width: 20px;
//     line-height: 0px;
//     font-size: 0px;
//     cursor: pointer;
//     background: transparent;
//     color: transparent;
//     top: 50%;
//     -webkit-transform: translate(0, -50%);
//     -ms-transform: translate(0, -50%);
//     transform: translate(0, -50%);
//     padding: 0;
//     border: none;
//     outline: none;
//     &:hover, &:focus {
//         outline: none;
//         background: transparent;
//         color: transparent;
//         &:before {
//             opacity: $slick-opacity-on-hover;
//         }
//     }
//     &.slick-disabled:before {
//         opacity: $slick-opacity-not-active;
//     }
//     &:before {
//         font-family: $slick-font-family;
//         font-size: 20px;
//         line-height: 1;
//         color: $slick-arrow-color;
//         opacity: $slick-opacity-default;
//         -webkit-font-smoothing: antialiased;
//         -moz-osx-font-smoothing: grayscale;
//     }
// }
//
// .slick-prev {
//     left: 25px;
//     [dir="rtl"] & {
//         left: auto;
//         right: 25px;
//     }
//     &:before {
//         content: $slick-prev-character;
//         [dir="rtl"] & {
//             content: $slick-next-character;
//         }
//     }
// }
//
// .slick-next {
//     right: 25px;
//     [dir="rtl"] & {
//         left: 25px;
//         right: auto;
//     }
//     &:before {
//         content: $slick-next-character;
//         [dir="rtl"] & {
//             content: $slick-prev-character;
//         }
//     }
// }
//
// /* Dots */
//
// .slick-dotted.slick-slider {
//     margin-bottom: 30px;
// }
//
// .slick-dots {
//     position: absolute;
//     bottom: -25px;
//     list-style: none;
//     display: block;
//     text-align: center;
//     padding: 0;
//     margin: 0;
//     width: 100%;
//     li {
//         position: relative;
//         display: inline-block;
//         height: 20px;
//         width: 20px;
//         margin: 0 5px;
//         padding: 0;
//         cursor: pointer;
//         button {
//             border: 0;
//             background: transparent;
//             display: block;
//             height: 20px;
//             width: 20px;
//             outline: none;
//             line-height: 0px;
//             font-size: 0px;
//             color: transparent;
//             padding: 5px;
//             cursor: pointer;
//             &:hover, &:focus {
//                 outline: none;
//                 &:before {
//                     opacity: $slick-opacity-on-hover;
//                 }
//             }
//             &:before {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 content: $slick-dot-character;
//                 width: 20px;
//                 height: 20px;
//                 font-family: $slick-font-family;
//                 font-size: $slick-dot-size;
//                 line-height: 20px;
//                 text-align: center;
//                 color: $slick-dot-color;
//                 opacity: $slick-opacity-not-active;
//                 -webkit-font-smoothing: antialiased;
//                 -moz-osx-font-smoothing: grayscale;
//             }
//         }
//         &.slick-active button:before {
//             color: $slick-dot-color-active;
//             opacity: $slick-opacity-default;
//         }
//     }
// }
