@charset "UTF-8";

.orico_wrap {
    text-align: justify;
    margin-bottom: 50px;
    p {
        margin-bottom: 20px;
    }
}

.orico_box--wrap {
    margin-bottom: 20px;
}

.orangebookBox_table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    border: 1px solid $gray2;
    .orangebookBox_table--title {
        text-align: center;
    }
    th,
    td {
        padding: 10px;
        border: 1px solid $gray2;
    }
    td {
        height: 100px;
        @include fontsize(18);
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .orangebookBox_table--th {
        background-color: lighten($yellow, 20%);
    }
    th {
        width: 210px;
        text-align: left;
        background-color: lighten($yellow, 40%);
        li {
            position: relative;
            padding-left: 15px;
            &::before {
                content: "※";
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        aside {
            @include fontsize(12);
        }
    }
    @include res($break) {
        tr {
            display: flex;
            flex-direction: column;
        }
        th {
            width: 100%;
            text-align: center;
            li {
                br {
                    display: none;
                }
                &::before {
                    position: static;
                }
            }
        }
    }
}

.onetime_body {
    background-color: $gray2;
    height: 100vh;
}

.onetime_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
    .onetime_wrap {
        margin-top: 70px;
        position: relative;
        width: 100%;
        padding: 10px;
        padding-top: 60px;
        padding-bottom: 30px;
        border-radius: 6px;
        background-color: #fff;
        filter: drop-shadow(1px 1px 2px #000);
    }
    .onetime_img {
        display: block;
        position: absolute;
        top: -39px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 90px;
        background-color: #fff;
        border-radius: 50%;
        padding: 10px;
        border: 3px solid $gray2;
    }
    .onetime_title {
        @include fontsize(18);
        margin-bottom: 30px;
    }
    .maxwidth {
        width: 100%;
        max-width: 400px;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
    }
    .pr_submit {
        margin-top: 50px;
    }
}

.onetime_done {
    .pr_submit {
        margin-top: 50px;
    }
}

.pr_confilmbox {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    padding: 20px;
    .pr_confilmbox--dd {
        text-align: justify;
        align-items: center;
        .unit_value {
            @include fontsize(16);
            font-weight: normal;
            margin-left: 10px;
        }
    }
}
