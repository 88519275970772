@charset "UTF-8";

.registmember {
    .registmember_select {
        display: block;

        .pr_selectArrow {
            width: 100%;
        }
    }

    &.width80 {
        width: 80%;
        margin: auto;
    }

    select {
        border: none;
    }

    @include res($break) {
        &.width80 {
            width: 100%;
        }
    }
}

.registmember_title {
    @include fontsize(18);
    &.MT {
        margin-top: 50px;
        margin-bottom: 20px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 30px;

    span {
        @include fontsize(40);
        color: $red;
        @include m1plus;
        font-weight: bold;
        &.registmember_title--sub {
            @include fontsize(14);
            display: block;
            color: #333;
            @include noto;
            font-weight: normal;
        }
    }

    &.sub {
        font-weight: bold;
        @include fontsize(20);
        margin-bottom: 10px;

        &::after {
            background-color: $blue;
        }
    }

    &::after {
        content: "";
        width: 200px;
        height: 2px;
        background-color: $orange;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.registmember_title2 {
    margin-top: 15px;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
    border: 2px solid $blue;
    padding: 10px;
    border-radius: 6px;
    background-color: #fff;
    @include fontsize(20);

    .registmember_title2--sub {
        display: block;
        @include fontsize(14);
        margin-top: 2px;
    }

    &::after {
        position: absolute;
        z-index: 1;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 19px 19px 0 19px;
        border-color: $blue transparent transparent transparent;
    }
}

.registmember_text {
    text-align: left;
    line-height: 2;
    @include fontsize(18);
    margin-bottom: 20px;
}

.registmember_numberlist--tit {
    font-weight: bold;
    position: relative;
    @include fontsize(20);
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 10px;

    ruby {
        @include fontsize(30);
        font-style: italic;
        font-weight: bold;
        color: $blue;
    }

    &::after {
        content: "";
        height: 2px;
        background-color: $blue;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    @include res($break) {
        span {
            display: block;
        }
    }
}

.registmember_numberlist {
    border: 1px solid $gray2;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 50px;
    text-align: justify;

    > li {
        display: flex;
        align-items: flex-end;
        position: relative;
        margin: auto;
        padding-left: 50px;
        padding-bottom: 10px;

        i {
            width: 40px;
            display: block;
            text-align: center;
            @include fontsize(18);
            color: $blue;
            font-weight: bold;
            position: absolute;
            top: 0;
            left: 0;
            white-space: nowrap;
        }
    }

    &.DOT {
        i {
            font-style: normal;
            color: $gray;
        }
    }
    &.RED {
        height: 260px;
    overflow: scroll;
    margin-bottom: 31px;
        i {
            color: $red;
        }
    }
    .registmember_numberlist--num {
        display: block;
        ul {
            li {
                i {
                        @include fontsize(12);
                        top: 5px;
                }
            }
        }
    }
}

.registmember_numberlist2 {
    &--num {
        display: flex;
        align-items: flex-end;
        position: relative;

        margin: auto;
        padding-left: 50px;
        padding-top: 10px;
        padding-bottom: 5px;
        @include fontsize(16);
        text-align: left;

        i {
            width: 40px;
            display: block;
            text-align: center;
            @include fontsize(25);
            color: $red;
            font-weight: bold;
            position: absolute;
            top: 0;
            left: 0;
            white-space: nowrap;
        }
    }
}

.registmember_numberlist3 {
    &--num {
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 1.5;
        display: flex;
        align-items: flex-end;
        position: relative;

        margin: auto;
        padding-left: 2rem;
        @include fontsize(14);
        text-align: left;

        i {
            width: 40px;
            display: block;
            text-align: center;
            @include fontsize(12);
            font-weight: bold;
            position: absolute;
            top: 5px;
            left: 2.4rem;
            white-space: nowrap;
            font-style: normal;
        }
    }
}

.registmember_numberlist4 {
    margin-bottom: 50px;
    @include res($iphoneplus) {
        text-align: left;
    }
}



.registselectwrap {
    margin-top: 50px;
}

.registwrap_warap {
    text-align: left;

    > li {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 5px;

        .right {
            flex: 1 1 0%;
            display: flex;

            align-items: center;
            justify-content: flex-end;

            a {
                width: 150px;
                text-align: center;
                background-color: $green;
                border-radius: 6px;
                padding: 5px;
                @include fontsize(14);
            }

            &::before {
                margin-right: 10px;
                content: "";
                background-color: $gray2;
                height: 1px;
                flex: 1 1 0%;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.registselect_title {
    margin-top: 10px;
    text-align: left;
    padding: 10px;
    background-color: lighten($orange, 20%);
    border-left: 20px solid darken($orange, 10%);
    margin-bottom: 10px;

    &.sub {
        background-color: transparent;
        border-left: none;
        border-bottom: 2px solid lighten($orange, 20%);
    }
}

.registmember_maintenancetype {
    dd {
        height: 100%;

        select {
            height: 40px;
        }

        p {
            span {
                display: block;
            }
        }
    }
}

.registmember_br {
    @include res($break) {
        br {
            display: none;
        }
    }
}

.registmember_txt {
    margin-bottom: 20px;
}

.registmember_submit {
    margin-top: 50px;
}

.registmember_yearpricewrap {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    &.NOUKA {
        margin-top: 15px;
        margin-bottom: 30px;
        justify-content: center;
        .yearprice_card {
            width: 100%;
        }
        .yearprice_cardtxt {
            text-align: center;
        }
    }

    &.BANK {
        justify-content: center;
        dt,
        dd {
            margin-right: 5px;
        }
    }

    &.TWO {
        justify-content: center;

        .yearprice_card {
            &:first-of-type {
                margin-right: 20px;
            }
        }
    }

    @include res($break) {
        display: block;
        .yearprice_card {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

.yearprice_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    border: 2px solid $gray2;
    width: 32%;
    overflow: hidden;

    dt {
        @include m1plus;
        @include fontsize(20);
        font-weight: bold;
        padding: 15px;
        position: relative;

        .OLD {
            color: $blue;
            @include fontsize(25);
        }

        .NEW {
            color: $red;
            @include fontsize(25);
        }

        .AND {
            @include fontsize(14);
        }

        &::after {
            content: "";
            width: 200px;
            height: 2px;
            background-color: $orange;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .yearprice_price {
        color: $red;
        @include fontsize(20);
        padding: 15px;
    }

    .yearprice_cardtxt {
        background-color: lighten($orange, 20%);
        @include fontsize(15);
        line-height: 2;
        padding: 10px;
        text-align: justify;
    }
}

.registmember_table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    tr {
        background-color: #e6f2f5;
        padding: 0.35em;
        border-bottom: 2px solid #fff;
    }

    th,
    td {
        padding: 1em 10px 1em 1em;
        border-right: 2px solid #fff;
    }

    .registmember_title3 {
        @include fontsize(20);

        span {
            display: block;
            @include fontsize(14);
        }
    }

    thead tr {
        background-color: #167f92;
        color: #fff;
    }

    tbody th {
        background: #78bbc7;
        color: #fff;
    }

    .registmember_table--dl {
        dd {
            color: $red;
        }
    }
    &.BANK {
        margin: 0 auto;
        margin-bottom: 30px;
    }
}

.registmember_table2 {
    @include fontsize(14);
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-top: 2px solid $gray2;
    border-left: 2px solid $gray2;
    margin-bottom: 5px;
    tr {
        background-color: fff;
        padding: 0.35em;
        border-bottom: 2px solid $gray2;
    }

    th,
    td {
        padding: 1em 10px 1em 1em;
        border-right: 2px solid $gray2;
    }

    .shop_name,
    .name,
    .url {
        background-color: #e6f2f5;
    }

    .address {
        text-align: left;
    }

    .link_btn {
        padding: 5px;
        border-radius: 6px;
        background-color: $red;
        color: #fff;
        display: inline-block;
    }

    .link_btn2 {
        padding: 5px;
        border-radius: 6px;
        background-color: $blue;
        color: #fff;
        display: inline-block;
    }

    .linknone {
        color: lighten($gray, 20%);
    }
    @include res($break) {
        width: auto;
        .shop_name {
            width: 20%;
        }
    }
}

.registmember_table3 {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    tr {
        background-color: #e6f2f5;
        padding: 0.35em;
        border-bottom: 2px solid #fff;
    }

    th,
    td {
        padding: 1em 10px 1em 1em;
        border-right: 2px solid #fff;
    }

    .registmember_title3 {
        @include fontsize(18);

        span.RED {
            color: #000;
            display: block;
            font-weight: bold;
        }
    }

    p.RED {
        @include fontsize(18);
        color: $red;
        font-weight: bold;
        margin-bottom: 5px;
    }
    p.INQ1 {
        @include fontsize(16);
    }
    p.INQ2 {
        margin-top: 10px;
        @include fontsize(13);
        text-align: justify;
    }

    thead tr {
        background-color: #167f92;
        color: #fff;
    }

    tbody th {
        background: #78bbc7;
        color: #fff;
    }

    .registmember_table--dl {
        dd {
            color: $red;
        }
    }
    &.BANK {
        margin: 0 auto;
        margin-bottom: 30px;
    }

    @include res($break) {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        overflow: auto;
        white-space: nowrap;
    }
}

.registmember_kaisei {
    @include fontsize(14);
    margin-top: 10px;
    text-align: right;
    margin-top: 50px;
}

.registshoplist_right {
    text-align: right;
    margin-bottom: 20px;
}

.registmember_option {
    margin-top: 30px;
    position: relative;

    &--click {
        background-color: $blue;
        color: #fff;
        border-radius: 6px;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 30px;

        &:hover {
            opacity: 1;
        }
    }

    &--day {
        text-align: right;
    }

    &::after {
        position: absolute;
        z-index: 1;
        top: -20px;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 40px 20px 40px;
        border-color: transparent transparent $blue transparent;
    }
}

.regist_width {
    margin-left: auto;
    margin-right: auto;
}

.table-of-contents {
    width: 100%;
    position: relative;
    text-align: left;
    border: 1px solid $gray;
    padding: 40px;
    padding-top: 60px;
    margin-bottom: 60px;
    li {
        list-style: outside;
        padding-bottom: 5px;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: "ページ内リンク";
        color: #fff;
        background-color: $gray;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    }
}

.registshoplist {
    background-color: $gray;
    text-align: left;
    color: #fff;
    border-left: 20px solid $blue;
    padding-left: 10px;
    margin-bottom: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    @include fontsize(19);
}

.registshoplist_map {
    margin-bottom: 30px;
    &--shop {
        @include fontsize(20);
        .RED {
            @include fontsize(25);
            color: $red;
            font-weight: bold;
        }
    }
    &--wrap {
        margin-top: 20px;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        &::before {
            pointer-events: none;
            content: "";
            display: block;
            padding-top: 56.25%; /* 高さを幅の75%に固定 */
        }
    }

    &--area {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        a {
            color: #fff;
            border-radius: 3px;
            padding: 5px;
            width: 10%;
            background-color: $blue;
            margin-bottom: 2px;
            border-right: 2px solid #fff;
        }
    }
    @include res($res1000) {
        a {
            width: 20%;
        }
    }
    @include res($break) {
        a {
            @include fontsize(14);
        }
    }
}

.registshoplist_notice {
    text-align: left;
    @include fontsize(14);
}

.registshoplist_box {
    &--txt {
        text-align: justify;
        line-height: 2;
        margin-bottom: 40px;
    }
    &--list {
        display: flex;
        margin-bottom: 5px;
        dt {
            background-color: lighten($orange, 20%);
            width: 200px;
            border-radius: 6px;
            padding: 5px;
            span {
                font-weight: bold;
            }
        }
        dd {
            flex: 1 1 0%;
            text-align: left;
            margin-left: 10px;
        }
        &:first-of-type {
            margin-top: 10px;
        }
    }
    @include res($break) {
        &--list {
            flex-direction: column;
            margin-bottom: 10px;
            dt {
                width: 100%;
            }
        }
    }
}

.pr_slide {
    @include res($break) {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden;
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 10px;
        overflow-scrolling: auto;
        overflow-y: scroll;
    }
}
