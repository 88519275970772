@charset "UTF-8";

.mypage_makerlist {
    display: flex;
    flex-wrap: wrap;
}

.mypage_makerlist--maker {
    display: inline-block;

    background-color: $gray2;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-right: 5px;
    transition: all 0.2s;
    a {
        display: block;
        padding: 6px;
        width: 100%;
    }
    &:hover {
        background-color: $gray;
        a {
            color: #fff;
        }
    }
}

.mypage_makerlist--tab {
    border-bottom: 3px solid $green;
    margin-bottom: 20px;
    li {
        width: 32%;
    }
    @include res($break) {
        display: block;
        border-bottom: none;
        overflow: initial;
        li {
            width: 100%;
            border-bottom: 3px solid $green;
            &:nth-child(n + 1) {
                margin-bottom: 10px;
            }
        }
    }
}

.mypage_makeredit--radio {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 24%;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
        background-color: $gray2;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    &::before {
        content: "";
        display: block;
        width: 24%;
        order: 1;
    }
    &::after {
        content: "";
        display: block;
        width: 24%;
    }
    @include res($break) {
        ul {
            width: 49%;
        }
        &::before {
            width: 49%;
        }
        &::after {
            content: none;
        }
    }
}

.mypage_makeredit--name {
    margin-bottom: 5px;
}

.mypage_makeredit--textarea {
    display: block;

    dt {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    dd {
        height: 300px;
    }
}

.mypage_makeredit--submit {
    margin-bottom: 60px;
    input[type="submit"] {
        padding: 5px 10px;
        width: auto;
    }
}

.mypage_makerupdate--list {
    tr:not(.list_title) {
        &:hover {
            background-color: lighten($gray2, 8%);
        }
    }
    th,
    td {
        padding: 5px;
    }
    th {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.pr_mypageradio {
    display: flex;
    input[type="radio"] {
        visibility: hidden !important;
        display: none;
        & + label {
            display: inline-block;
            width: 100%;
            padding: 0 4px;

            cursor: pointer;
            transition: 0.2s;
            background-color: #fff;
        }
        &:checked {
            & + .radio_on {
                background-color: $green;
            }
            & + .radio_off {
                background-color: $gray;

                color: #fff;
            }
        }

        // &:not(:checked) + label:hover {
        //     background: rgba(103, 171, 35, 0.4); /* lebelの背景 */
        // }
    }
    .radio_on {
        border-radius: 5px 0 0 5px;
        &:checked {
            background-color: $green;
        }
    }
    .radio_off {
        border-radius: 0 5px 5px 0;
        &:checked {
            background-color: $gray;
        }
    }
}

.mypage_makerupdate--name {
    width: 20%;
}

.mypage_makerupdate--seishiki {
    width: auto;
}

.mypage_makerupdate--yomi {
    width: 20%;
}

.mypage_makerupdate--check {
    width: 10%;
}
