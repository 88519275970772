@charset "UTF-8";

.sub_main {
    background-color: $gray2;
    padding-bottom: 150px;
}

.pr_separate {
    display: flex;
    justify-content: space-between;

    .un_left {
        width: 240px;
        background-color: #fff;
        box-sizing: border-box;

        &.sub_left {
            background-color: transparent;
            padding-right: 20px;

            .subLeft_nav {
                border-radius: 6px;
                overflow: hidden;

                > ul {
                    > li {
                        border-bottom: 1px solid $gray2;
                    }
                }

                a,
                dt {
                    position: relative;
                    text-align: left;
                    display: block;
                    min-height: 48px;
                    padding: 16px;
                    background-color: #fff;
                }

                dd {
                    a {
                        padding-left: 30px;
                        background-color: darken(#fff, 5%);
                    }
                }

                a {
                    &::after {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 10px;
                        margin: auto;
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                    }
                }
            }
        }

        @include hack(11) {
            width: 20%;
        }

        @include res($break) {
            padding-left: 8px;
            padding-right: 8px;
            width: 100%;

            @include hack(11) {
                width: 100%;
            }
        }
    }

    .un_right {
        // flex: 1 1 0%;
        // width: 100%;
        width: calc(100% - 240px);

        @include hack(11) {
            width: 80%;

            @include res($break) {
                width: 100%;
            }
        }
    }

    @include res($break) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;

        .un_left {
            width: 100%;
            order: 2;
            padding-left: 0;
            padding-right: 0;
                    &.sub_left {
            background-color: transparent;
            padding-right: 0;
                    }

            .un_sec_mdl {
                display: flex;
                flex-wrap: wrap;

                .pr_product_under {
                    width: 50%;
                }
            }
        }

        .un_right {
            width: 100%;
            order: 1;
            border-radius: 0;
        }
    }
}

.pr_togglePluse {
    position: relative;

    &.HEADER {
        display: block;
        height: 100%;
        &.pr_togglePluse {
            position: static;
            .togglePluse_pulus {
                a {
                    &:hover {
                        opacity: 1;
                    }
                }
                &::before {
                    content: none;
                }
                &::after {
                    transform: translateY(0);
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: initial;
                    bottom: 2px;
                    @include fontsize(20);
                    content: "\f0dd";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    background-color: transparent;
                    width: initial;
                    height: initial;
                }
            }
        }
        ul {
            transition: all 0.1s;
            transform: translateY(-20px);
            opacity: 0;
            position: absolute;
            left: 0;
            width: 100%;
            display: flex;
            background-color: lighten($orange, 20%);
            li {
                width: calc(100% / 3);
            }
        }
        .togglePluse_pulus.is_active + .togglePluse_minus {
            ul {
                transform: translateY(0);
                opacity: 1;
                z-index: 1;
            }
        }
        .is_active.togglePluse_pulus {
            background-color: lighten($orange, 20%);
            &::after {
                transform: translateY(3px);
            }
        }
        &:hover {
            .togglePluse_pulus {
                &::after {
                    transform: translateY(3px);
                }
            }
        }
        @include res($break) {
            &.pr_togglePluse {
                .togglePluse_pulus {
                    &::after {
                        right: 10px;
                        left: initial;
                    }
                }
            }
            ul {
                position: static;
                li {
                    width: 100%;
                }
            }
        }
    }

    .togglePluse_pulus {
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: #333;
            transition: all 0.1s;
            border-radius: 2px;
        }

        &::before {
            right: 16px;
            width: 4px;
            height: 16px;
            transform: rotate(0deg);
        }

        &::after {
            right: 10px;
            width: 16px;
            height: 4px;
        }

        &.is_active {
            &::before {
                transform: rotate(90deg);
            }
        }
    }

    .togglePluse_minus {
        display: none;
    }
}

.pr_main_box {
    padding-bottom: 120px;
}

.pr_item_title {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    background-color: $main_color_li;
    border-left: 20px solid $main_color;
    padding: 5px 5px 5px 20px;
    @include fontsize(18);
}

.pr_item_title_gry {
    color: #fff;
    background-color: #a9a9a9;
    margin-bottom: 8px;
    padding: 4px;
    box-sizing: border-box;
    width: 100%;
}

.pr_subpagetitle {
    @include m1plus;
    @include fontsize(25);
    border-bottom: 2px solid $gray2;
    padding-bottom: 10px;
    margin-bottom: 50px;

    .NEW {
        color: $red;
        font-weight: bold;
    }

    .OLD {
        color: $blue;
        font-weight: bold;
    }
}

.pr_centertitle {
    position: relative;
    @include fontsize(18);
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 30px;

    .sideinfo {
        @include fontsize(14);
        line-height: 1.2;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    &::before {
        bottom: -19px;
        width: 0;
        height: 0;
        border-top: 10px solid $main_color;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
    }

    &::after {
        bottom: 0;
        width: 100px;
        height: 5px;
        background-color: $main_color;
    }
}

.pr_breadcrumbs {
    background-color: $sub_color;
    color: $sub_color_li;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 32px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    overflow: auto;
    white-space: nowrap;
    height: 39px;

    .breadcrumbs_home {
        background-color: $sub_color_da;

        i {
            @include fontsize(18);
            color: $sub_color_li;
            transition: all 0.2s;
            transform: scale(1);
        }

        &:hover {
            i {
                transform: scale(1.1);
            }
        }
    }

    .pr_active {
        position: relative;
        background-color: $sub_color_li;
        color: #fff;
        z-index: 1;
    }

    a {
        position: relative;
        color: $sub_color_da;
        display: block;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 7px;
            margin: auto;
            width: 1px;
            height: 1px;
            display: block;
            background-color: transparent;
            transition: all 0.3s;
        }

        &:hover {
            &::after {
                width: 100%;
                transform: scaleX(0.8);
                background-color: $sub_color_da;
            }
        }
    }

    span {
        display: inline-block;
        width: 100%;
        height: 100%;
        @include fontsize(14);
        padding: 10px;
        box-sizing: border-box;

        i {
            margin-left: 4px;
        }
    }

    @include res($break) {
        margin-left: 0;
        margin-right: 0;
    }
}

.breadcrumbs {
    @include fontsize(14);
    padding-top: 10px;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    overflow: auto;
    white-space: nowrap;

    li {
        position: relative;
        margin-right: 30px;

        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -20px;
            margin: auto;
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            display: flex;
            align-items: center;
        }

        &:last-child {
            margin-right: 0;

            &::after {
                content: none;
            }
        }

        &:hover {
            &:not(.breadcrumbs_home) {
                a {
                    text-decoration: underline;
                }
            }
        }
    }

    a {
        color: $sub_color_da;
        display: block;
    }

    .breadcrumbs_home {
        background-color: $sub_color_da;
        border-radius: 50%;
        padding: 5px;

        i {
            @include fontsize(18);
            color: $sub_color_li;
            transition: all 0.3s;
            transform: scale(1);
        }

        &:hover {
            i {
                transform: scale(1.1);
            }
        }
    }

    .breadcrumbs_current {
        font-weight: bold;
    }

    a[onclick] {
        cursor: pointer;
    }
}

.pr_un_section_box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
}

.pr_special_price {
    @include fontsize(18);
    font-weight: bold;
    color: $red;
}

.pr_sale {
    @include fontsize(18);
    font-weight: bold;
    color: #fff;
    background-color: $red;
    padding: 10px;
    border-radius: 3px;
}

.pr_negotie {
    @include fontsize(18);
    font-weight: bold;
    color: #fff;
    background-color: $yellow;
    padding: 10px;
    border-radius: 3px;
}

.pr_product_state {
    width: 50%;
    display: flex;
    height: 54px;
    line-height: 1.2;
    margin-bottom: 8px;

    dd,
    dt {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        box-sizing: border-box;
        text-align: center;
    }

    dt {
        border-bottom: 1px solid $gray_li;
        background-color: $gray;
        color: #fff;
        width: 50%;
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: -8px;
            z-index: -1;
            margin: auto;
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background-color: $gray;
            transform: rotate(45deg);
        }
    }

    dd {
        border-bottom: 1px solid $gray;
        width: 50%;
        background-color: #fff;
        text-align: left;
        padding: 5px 5px 5px 20px;
    }

    @include res($res800) {
        flex-wrap: wrap;
        width: 100%;
    }
}

.pr_listBox {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    li {
        padding: 10px;
        box-sizing: border-box;
        text-align: left;

        &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            text-align: center;
            background-color: $gray;
            position: relative;
            color: #fff;
        }

        &:last-child {
            width: 70%;
            background-color: #fff;
            box-sizing: border-box;
            padding-left: 20px;
            border-bottom: 1px solid $gray;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include res($res800) {
        flex-direction: column;

        li {
            &:first-child {
                justify-content: center;
                width: 100%;

                p {
                    width: auto;
                }

                &::after {
                    bottom: -20px;
                    left: 0;
                    right: 0;
                    top: auto;
                    margin: auto;
                    border-top-color: $main_color_li;
                    border-left-color: transparent;
                }
            }

            &:last-child {
                width: 100%;
                text-align: center;
                border-bottom: 0;
            }
        }
    }
}

.pr_formBox {
    li {
        &:first-child {
            background-color: $gray_li;

            > p {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 70%;
                text-align: left;

                .must {
                    @include fontsize(13);
                    padding: 2px;
                    box-sizing: border-box;
                    font-weight: bold;
                    background-color: #fff;
                    border-radius: 3px;
                    color: $gray_li;
                    margin-left: 5px;
                }
            }
        }

        &:last-child {
            padding-left: 20px;
            justify-content: flex-start;
            border-bottom: 1px solid $gray_li;

            select {
                height: 35px;
            }

            input,
            select {
                box-sizing: border-box;
                @include fontsize(18);
                padding: 3px;
                width: 100%;
                border: 1px solid #a3a1a1;
            }

            input[type="radio"] {
                width: 13px;
            }

            input[type="file"] {
                border: none;
                box-shadow: none;
                @include fontsize(16);
                width: 100%;

                @include hack(11) {
                    width: 50%;
                }
            }

            textarea {
                box-sizing: border-box;
                @include fontsize(16);
                width: 100%;
                padding: 3px;
                border: 1px solid #a3a1a1;
            }

            .check {
                @include fontsize(13);
                margin-left: 5px;
            }
        }

        &:last-child.ptn_addNo {
            span {
                @include fontsize(20);
            }

            input {
                width: 20%;

                &:first-of-type {
                    margin-right: 5px;
                }

                &:last-of-type {
                    margin-left: 5px;
                }
            }
        }
    }

    .form_number {
        display: flex;

        span {
            @include fontsize(18);
            font-weight: bold;
            margin-left: 8px;
            margin-right: 8px;
        }

        input {
            width: 100px;
        }
    }

    @include res($res800) {
        width: 100%;
        flex-direction: column;

        li {
            &:first-child {
                justify-content: flex-start;

                > p {
                    width: auto;
                }
            }

            &:last-child {
                flex-wrap: wrap;
                border-bottom: 0;

                .check {
                    display: block;
                    text-align: left;
                }
            }
        }

        .form_number {
            &:last-child {
                flex-wrap: nowrap;
            }
        }
    }
}

//オリコ
.pr_orico_box {
    margin-bottom: 32px;

    .orico_title {
        border-radius: 3px 3px 0 0;
        @include fontsize(16);
        padding: 5px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        background-color: $gray_li;
    }

    .orico_wrap {
        padding: 16px;
        background-color: #f2f2f2;

        .orico_descrip {
            @include beta;
            text-align: left;
            padding-bottom: 10px;

            a {
                margin-top: 8px;
                padding: 8px;
                border-radius: 3px;
                background-color: #999;
                display: inline-block;
                float: right;
                transition: all 0.2s;

                &:hover {
                    background-color: #666;
                    color: #fff;
                }
            }

            @include res($res800) {
                @include fontsize(13);
            }

            @include boxafter;
        }

        .orico_form {
            .orico_payment {
                display: flex;
                flex-direction: column;
                padding-bottom: 32px;

                li {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 8px;

                    input[type="text"],
                    select {
                        border-top: 1px solid #ccc;
                        border-right: 1px solid #ccc;
                        border-bottom: 1px solid #ccc;
                        border-left: 0;
                        @include fontsize(16);
                        padding: 10px;
                        box-sizing: border-box;
                        height: 50px;
                        color: #aaa;
                        border-radius: 0 3px 3px 0;
                        width: 80%;
                        background-color: #fff;
                    }

                    span {
                        @include fontsize(14);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 20%;
                        padding: 8px;
                        background-color: $gray_li;
                    }
                }

                .orico_installments {
                    position: relative;

                    select {
                        cursor: pointer;
                    }

                    &::after {
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f0dd";
                        @include fontsize(24);
                        display: block;
                        position: absolute;
                        top: 5px;
                        right: 8px;
                        pointer-events: none;
                        color: $gray_li;
                    }
                }

                .oricoCredit_btn {
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 0;

                    input {
                        border: none;
                        cursor: pointer;
                        height: 52px;
                        color: #fff;
                        background-color: $green;
                        @include fontsize(13);
                        font-weight: bold;
                        width: 200px;
                        border-radius: 3px;
                        transition: all 0.2s;

                        &:hover {
                            background-color: darken($green, 20%);
                        }
                    }

                    @include res($break) {
                        input {
                            width: 100%;
                        }
                    }
                }
            }

            .orico_priceSimulator {
                display: flex;
                justify-content: space-between;

                dl {
                    display: flex;
                    flex-direction: column;
                    width: 25%;

                    dt {
                        display: flex;
                        @include fontsize(13);
                        color: #fff;
                        background-color: $gray;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        border-right: 1px solid $gray_li;
                        padding: 10px;
                        box-sizing: border-box;
                    }

                    dd {
                        background-color: #fff;
                        border-right: 1px solid $gray_li;
                        @import url("https://fonts.googleapis.com/css?family=Quantico");
                        font-family: "Quantico", sans-serif;
                        color: #293e66;
                        @include fontsize(18);
                        font-weight: bold;
                        padding: 10px;
                        box-sizing: border-box;
                        text-align: left;
                    }

                    &:last-child {
                        dd,
                        dt {
                            border-right: 0;
                        }
                    }
                }

                @include res($break) {
                    display: block;

                    dl {
                        width: 100%;
                        margin-bottom: 8px;

                        dt {
                            border-right: 0;

                            br {
                                display: none;
                            }
                        }

                        dd {
                            border-right: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .orico_attention {
                text-align: right;
                @include fontsize(13);
                padding-top: 8px;
            }
        }
    }

    .orico_footer {
        display: flex;
        justify-content: center;
        padding: 10px;
        text-align: center;
        background-color: $gray_li;
        border-radius: 0 0 3px 3px;
        @include fontsize(12);

        a {
            display: block;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

// アップフォーム
.pr_registList {
    display: flex;

    text-align: left;
    margin-bottom: 5px;

    dt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 24%;
        padding: 18px;
        background-color: #e5e3a3;
        border-radius: 6px;
    }

    dd {
        display: flex;
        align-items: center;
        flex: 1 1 0%;
        padding: 10px 20px;
        background-color: $gray2;
        border-radius: 6px;
        margin-left: 10px;

        .form_yen {
            display: flex;
            align-items: flex-end;
            padding-left: 10px;
        }

        input[type="text"],
        input[type="mail"],
        input[type="tel"] {
            border-radius: 6px;
            display: block;
            width: 100%;
            border: none;
            height: 100%;
            padding-left: 10px;
            box-sizing: border-box;
            @include fontsize(16);
        }
        textarea {
            width: 100%;
            height: 100%;
            padding: 10px;
            font-size: 16px;
            font-size: 1rem;
            border: 1px solid $gray2;
            border-radius: 6px;
        }
    }

    .pr_must {
        margin-left: 10px;
        margin-right: 10px;
    }

    .form_must {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .price_oudan {
        width: 109px;
        margin-left: 10px;
    }

    &.listType_zip {
        > dd {
            display: flex;
            align-items: center;
        }
        .listType_zip--1 {
            width: 90px;
            text-align: center;
            padding-left: 0;
        }

        .listType_zip--hyphen {
            padding-left: 10px;
            padding-right: 10px;
        }

        .listType_zip--2 {
            width: 120px;
            text-align: center;
            padding-left: 0;
        }
    }

    &.NAME2 {
        input {
    
                width: 45%;
      
        }
    }

    @include res($res1100) {
        display: block;
        margin-bottom: 10px;

        dt {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            border-radius: 5px 5px 0 0;
        }

        dd {
            margin-top: 0;
            margin-left: 0;
            padding: 10px 20px;
            height: 60px;
            border-radius: 0 0 5px 5px;
        }

        // li {
        //     &:first-child {
        //         width: 100%;
        //         padding-top: 5px;
        //         padding-bottom: 5px;
        //         padding-left: 10px;
        //         border-radius: 5px 5px 0 0;
        //     }
        //     &:nth-child(2) {
        //         margin-top: 0;
        //         margin-left: 0;
        //         padding: 10px 20px;
        //         height: 60px;
        //         border-radius: 0 0 5px 5px;
        //     }
        // }
        .price_radio,
        .price_oudan {
            margin-top: 5px;

            p {
                margin-left: 0 !important;
            }

            .price_radio_baiyaku {
                margin-right: 10px;
            }
        }

        .price_oudan {
            width: 100%;
            margin-left: 0;
        }

        .form_must {
            display: block;
        }
    }
}

.pr_registTitle {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include fontsize(16);
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;

    &.NEW {
        background-color: $red;
        border-left: 20px solid darken($red, 20%);

        .form_reset {
            background-color: darken($red, 20%);
        }
    }

    &.OLD {
        background-color: $blue;
        border-left: 20px solid darken($blue, 20%);

        .form_reset {
            background-color: darken($blue, 20%);
        }
    }
    &.EDIT {
        background-color: $green;
        border-left: 20px solid darken($green, 20%);

        .form_reset {
            background-color: darken($green, 20%);
        }
    }

    .form_reset {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        display: flex;
        align-items: center;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
        padding: 5px;
        height: 30px;
        transition: all 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }

    .registTitle_sub {
        display: inline-block;
        @include fontsize(12);
        margin-left: 20px;
        text-align: left;
    }

    @include res($break) {
        p {
            width: 90%;
            display: flex;
            justify-content: space-between;

            span {
                &:first-child {
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }
}

.pr_form_toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    height: 20px;

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: #fff;
        transition: all 0.1s;
    }

    &::before {
        left: 9px;
        top: 20%;
        width: 2px;
        height: 14px;
        transform: rotate(0deg);
    }

    &::after {
        left: 3px;
        top: 50%;
        width: 14px;
        height: 2px;
    }
}

.pr_form_toggle.toggleOpen {
    &::before {
        transform: rotate(90deg);
    }
}

.pr_formList_radio {
    label {
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 6px;
        background-color: $gray2;
        position: relative;
        cursor: pointer;
    }

    input {
        display: none;
    }

    li {
        .form_radio {
            width: 109px;
            margin-left: 10px;
        }
    }
}

.pr_listType1 {
    display: flex;
    border-bottom: 1px solid $gray2;
    padding-bottom: 10px;
    margin-bottom: 20px;

    input,
    select {
        &:not([type="checkbox"]):not([type="radio"]) {
            text-align: left;
            width: 100%;
            height: 100%;
            @include fontsize(18);
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid $gray;
            border-radius: 6px;
        }
    }

    textarea {
        width: 100%;
        height: 100%;
        padding: 10px;
        @include fontsize(16);
        border: 1px solid $gray;
        border-radius: 6px;
    }

    input[type="password"] {
        max-width: 236px;
        text-align: center !important;
    }

    > dt {
        width: 200px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pr_must {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    > dd {
        flex: 1 1 0%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .unit_value {
            @include fontsize(18);
            font-weight: bold;
            margin-left: 10px;
        }

        &.listType_textarea {
            height: auto;
        }

        &.listType_zip--area {
            max-width: 130px;
        }

        &.listType_zip {
            display: flex;
            align-items: center;

            .listType_zip--1 {
                width: 90px;
                text-align: center;
            }

            .listType_zip--hyphen {
                padding-left: 10px;
                padding-right: 10px;
            }

            .listType_zip--2 {
                width: 120px;
                text-align: center;
            }
        }
    }

    .listType_small {
        display: block;
        @include fontsize(13);
    }

    .listType_mail {
        display: flex;
        flex-wrap: wrap;
        height: auto;

        .listType_mail--devaice {
            > dt {
                flex: 1 1 0%;
                margin-right: 10px;
            }

            .pr_radiobox {
                width: 200px;
            }
        }

        .listType_mail--devaice,
        .listType_mail--type {
            width: 100%;
            display: flex;

            dd {
                display: flex;

                ul {
                    display: flex;
                }
            }
        }
    }

    .listType_radio {
        width: 100%;
        display: flex;
    }

    &.thumbnail {
        border-bottom: 0;

        dt {
            height: 60px;
            border-bottom: 1px solid $gray2;
            padding-bottom: 10px;
        }

        dd {
            padding-top: 20px;
            height: auto;
        }

        .thumbnail_input {
            text-align: left;
        }

        .thumbnail_img {
            img[src*="noimage"] {
                border: 1px solid $gray2;
            }
        }

        input[type="file"] {
            @include fontsize(13);
            padding: 0;
            border: 0;
            border-radius: initial;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include res($break) {
        display: block;

        dt {
            justify-content: center;
            text-align: center;
            width: 100%;
            margin-bottom: 5px;
        }

        dd {
            justify-content: center;
        }
    }

    @include res($break) {
        .movie_right {
            .pr_listType1 {
                &.thumbnail {
                    .thumbnail_input {
                        padding-bottom: 10px;
                    }

                    .thumbnail_img {
                        position: static;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.pr_listType2 {
    margin-bottom: 30px;

    dt {
        font-weight: bold;
        border-bottom: 1px solid $gray;
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .justify {
        text-align: justify;
    }

    textarea {
        padding: 10px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.pr_selectArrow {
    position: relative;
    height: 100%;

    select {
        width: 100%;
        @include fontsize(16);
        height: 100%;
        border: 1px solid #434343;
        border-radius: 6px;
        padding-left: 10px;
        padding-right: 10px;
    }

    &::after {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin-top: auto;
        margin-bottom: auto;
        width: 10px;
        height: 10px;
        border: 0;
        border-bottom: solid 2px #434343;
        border-right: solid 2px #434343;
        transform: rotate(45deg);
    }
}

.pr_displayN {
    display: none;

    .pr_displayNWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .pr_displayNWrap2 {
        display: block;
    }

    @include res($break) {
        .pr_displayNWrap {
            display: block;

            .pr_formList {
                width: 100%;

                li {
                    &:first-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.mypageFix_baseinfo {
    margin-bottom: 50px;
}

.pr_formButton {
    background-color: #a2cea9;
    color: #fff;
    border-radius: 6px;
    transition: all 0.2s;
    margin-left: 10px;

    &:hover {
        background-color: #83aa89;
    }
}

.pr_formSelectWrap {
    position: relative;

    select {
        appearance: none;
        border-radius: 0;
        border: 0;
        margin: 0;
        padding-left: 10px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: none transparent;
        vertical-align: middle;
        color: inherit;
        box-sizing: border-box;
        background-color: #fff;
        cursor: pointer;
        @include fontsize(16);
    }

    select::-ms-expand {
        display: none;
    }

    &::before {
        pointer-events: none;
        content: "";
        display: block;
        position: absolute;
        top: 40%;
        right: 7px;
        width: 0;
        height: 0;
        border-top: 10px solid #a6a6a6;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
    }
}

.pr_formMust {
    display: inline-block;
    background-color: #fff;
    border-radius: 3px;
    color: $red;
    @include fontsize(12);
    line-height: 1;
    width: 36px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    margin-right: 12px;

    @include res($break) {
        margin-left: 10px;
        margin-right: 0;
    }
}

.pr_donemessage {
    padding-bottom: 40px;
}

//ptn /////////////////////////////////////

.pdtop40 {
    padding-top: 40px;
}

.ptn_pd60_30 {
    padding: 60px 30px;
    box-sizing: border-box;
}

.ptn_pd60 {
    padding: 60px;
    box-sizing: border-box;
}

.ptn_newBox {
    width: 15.9090909091%;
    margin-right: 1%;
    border: 4px solid $main_color_li;
    border-radius: 10px;

    a {
        display: block;
        height: 100%;

        article {
            height: 100%;
            padding: 10px;
            box-sizing: border-box;

            .top_img {
                border: 2px solid $main_color_li;
            }

            .top_maker {
                margin-top: 5px;
                padding-top: 5px;
                padding-bottom: 5px;
                background-color: $main_color_li;
                color: #fff;
                @include fontsize(12);
            }

            .top_type {
                margin-top: 5px;
                @include fontsize(12);
                text-align: right;
            }

            .top_price {
                margin-top: 5px;
                border-top: 5px solid $main_color_li;
                padding-top: 8px;
                text-align: right;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.ptn_prBox {
    a {
        display: block;
        overflow: hidden;

        &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background-image: url("../img/top/bk_02.gif");
            background-repeat: repeat;
            background-color: rgba(0, 0, 0, 0.2);
            transition: all 0.2s;
        }

        &::after {
            content: "";
            display: block;
            box-sizing: border-box;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: $main_color_li;
            transform: scale(1);
            transition: all 0.2s;
        }

        &:hover {
            span {
                img {
                    opacity: 1;
                }
            }

            &::before {
                background-color: rgba(0, 0, 0, 0);
            }

            &::after {
                transform: scale(1.05);
            }
        }
    }

    @include res($break) {
        a {
            &::before {
                background-image: url();
            }
        }
    }
}

.ptn_makerLogo {
    position: relative;

    span {
        position: absolute;
    }
}

.ptn_prTxt {
    position: relative;

    span {
        text-align: left;
        position: relative;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        padding: 5px 20px;
        box-sizing: border-box;
    }
}

.ptn_prBR {
    span {
        bottom: 0;
        right: 0;
    }
}

.ptn_prTL {
    span {
        top: 0;
        left: 0;
    }
}

.ptn_pdtop5 {
    padding-top: 5px;
}

.ptn_pd5 {
    padding-top: 5px;
    padding-bottom: 5px;

    @include res($break) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.ptn_pd10 {
    padding: 10px;
}

.ptn_pd20 {
    padding: 20px;
}

.ptn_pd50 {
    padding-top: 60px;
    padding-bottom: 100px;

    @include res($res800) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.ptn_maTop5 {
    margin-top: 5px;
}

.ptn_maTop10 {
    margin-top: 10px;
}

.ptn_maTop50 {
    margin-top: 50px;
}

.ptn_marBtm5 {
    margin-bottom: 5px;
}

.ptn_marBtm10 {
    margin-bottom: 10px;
}

.ptn_marBtm20 {
    margin-bottom: 20px;
}

.ptn_marBtm30 {
    margin-bottom: 30px;
}

.ptn_gray {
    background-color: $gray;
}

.ptn_beta {
    text-align: justify;
    letter-spacing: 0.01em;
}

.ptn_img100 {
    img {
        width: 100%;
        height: auto;
    }
}

.ptn_floatRight {
    float: right;
}

//header

.ptn_address {
    display: flex;
    line-height: 1.1;

    .left {
        text-align: right;
    }

    .right {
        text-align: right;
        padding-left: 13px;
    }
}

// nav
.ptn_flex {
    display: flex;

    @include res($break) {
        display: none;
    }
}

// box2

.ptn_btmMenu {
    width: 24%;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    > div,
    > article {
        padding: 10px 10px 20px 10px;
    }

    img {
        width: 100%;
        height: auto;
    }

    time {
        display: block;
        text-align: right;
    }

    h2 {
        background-color: #586077;
        color: #fff;
        @include fontsize(14);
        padding: 10px;
    }

    h3 {
        @include fontsize(16);
    }

    .des {
        text-align: left;
    }

    p {
        padding-top: 5px;
        @include fontsize(14);
        text-align: left;
        line-height: 1.6;

        &::before {
            content: "　";
        }
    }

    @include boxafter;
}

.ptn_menu {
    width: 23.8888888889%;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include res($break) {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ptn_height336 {
    // margin-top: 5%;
    // height: 61.6513761468%;
    padding-top: 19%;
    box-sizing: border-box;

    @include hack(fox) {
        padding-top: 33%;
    }

    @include res($res900) {
        padding-top: 13%;

        @include hack(fox) {
            padding-top: 16%;
        }
    }

    @include res($res800) {
        padding-top: 0;
    }
}

.ptn_h2title {
    padding-top: 120px;
    padding-bottom: 40px;

    dl {
        color: $main_color;

        dt {
            @include fontsize(65);
            line-height: 1;

            @include res($break) {
                @include fontsize(47);
            }
        }

        dd {
            display: inline-block;
            @include fontsize(20);
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 7px solid $main_color;
        }
    }
}

.ptn_more {
    @include fontsize(13);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 25px;
    background-color: $main_color_li;
    border-radius: 3px;
    transition: all 0.2s;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #fff;
    }

    &:hover {
        background-color: $main_color_li;
    }
}

.ptn_moreMa {
    margin: 5px auto 0;
}

.ptn_news {
    dt {
        padding-bottom: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }

    dd {
        text-align: left;
    }
}

.ptn_newup {
    text-align: left;
    @include boxafter;
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px dotted $main_color_li;

    time {
        @include fontsize(13);
    }

    &:last-child {
        border-bottom: 0;
    }
}

.ptn_ulbox {
    height: 100%;
    color: #252525;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    text-align: left;
    background-color: #fff;
    border-radius: 0 0 10px 10px;

    img {
        width: 100%;
        height: auto;
    }

    @include hack(fox) {
        overflow: hidden;
    }

    @include res($break) {
        height: auto;
    }
}

.ptn_newupDl {
    dt {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .btm {
        padding-top: 10px;
        text-align: left;
        line-height: 1.5;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.ptn_scroll {
    height: auto;
    // overflow-y: scroll;
    overflow: hidden;
}

// shop_info

// item

.ptn_itemBox {
    transition: 0.1s;
}

.ptn_itemList {
    margin-bottom: 20px;
    position: relative;
    background-color: $main_color;

    dl {
        display: flex;
        justify-content: space-between;

        .item_img {
            width: 40%;
            overflow: hidden;
            background-color: #2a2b39;
            background-image: url("../img/item_menu/patterns01.png");
            background-size: 5px;

            a {
                display: flex;
                align-items: center;
                height: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    max-height: 100%;
                    transition: 0.3s;
                }

                &:hover {
                    img {
                        opacity: 1;
                        transition: 0.3s;
                        transform: scale(1.1);
                    }
                }
            }

            span {
                @include fontsize(12);
                position: absolute;
                bottom: -11px;
                left: 0;
                right: 0;
                margin: auto;
                padding: 3px 7px;
                border-radius: 10px;
                color: #fff;
                background-color: #181818;
                width: 80%;
                text-align: center;
                display: inline-block;
            }

            .item_maker {
                background-color: #3387b4;
            }
        }

        .item_box {
            border: 1px solid $main_color;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            width: 60%;
            text-align: left;

            // .oppo {
            //     span {
            //         &:first-child {
            //             color: transparent;
            //         }
            //
            //         &:last-child {
            //             color: yellow;
            //         }
            //     }
            // }
        }

        .item_name {
            display: flex;
            background-color: #fff;

            .item_maker {
                width: 40%;
                border-bottom: 1px solid $main_color;

                span {
                    &:first-child {
                        display: inline-block;
                        padding: 10px;
                        box-sizing: border-box;
                        background-color: $main_color;
                        color: #fff;
                    }

                    &:last-child {
                        display: inline-block;
                        padding: 10px;
                        box-sizing: border-box;
                    }
                }
            }

            .item_type {
                width: 50%;
                border-bottom: 1px solid $main_color;
                flex: 1 1 auto;

                span {
                    &:first-child {
                        display: inline-block;
                        padding: 10px;
                        box-sizing: border-box;
                        background-color: $main_color;
                        color: #fff;
                    }

                    &:last-child {
                        display: inline-block;
                        padding: 10px;
                        box-sizing: border-box;
                    }
                }
            }

            @include res($break) {
                display: block;

                .item_maker {
                    width: 100%;

                    span {
                        &:first-child {
                            width: 40%;
                            text-align: center;
                        }

                        &:last-child {
                            width: 60%;
                        }
                    }
                }

                .item_type {
                    width: 100%;

                    span {
                        &:first-child {
                            width: 40%;
                            text-align: center;
                        }

                        &:last-child {
                            width: 60%;
                        }
                    }
                }
            }
        }

        .item_des {
            flex: 1 1 auto;
            background-color: $main_color_li;
            line-height: 1.8;
            padding: 10px;
            box-sizing: border-box;
            min-height: 200px;
        }

        .item_credit {
            display: flex;
            justify-content: flex-end;
            background-color: $main_color;
            padding: 10px;
            box-sizing: border-box;
            min-height: 37px;

            p {
                text-align: right;
                padding-top: 5px;

                span {
                    &:first-child {
                        color: #fff;
                    }

                    &:last-child {
                        @include fontsize(20);
                        font-weight: bold;
                        color: #d24040;
                        text-shadow: 1px 1px 0 #8b2929;
                        letter-spacing: -0.05em;
                    }
                }
            }

            .oudan {
                span {
                    &:first-child {
                        color: transparent;
                    }

                    &:last-child {
                        @include fontsize(18);
                        display: inline-block;
                        border-radius: 2px;
                        background-color: #40c15d;
                        text-shadow: none;
                        color: #297c3c;
                        padding: 0 5px;
                        border-bottom: solid #297c3c;
                    }
                }
            }

            .syodan {
                span {
                    &:first-child {
                        color: transparent;
                    }

                    &:last-child {
                        @include fontsize(18);
                        display: inline-block;
                        border-radius: 2px;
                        background-color: #f7bd14;
                        text-shadow: none;
                        color: #9c7400;
                        padding: 0 5px;
                        border-bottom: solid #9c7400;
                    }
                }
            }

            .baiyaku {
                span {
                    &:first-child {
                        color: transparent;
                    }

                    &:last-child {
                        @include fontsize(18);
                        display: inline-block;
                        border-radius: 2px;
                        background-color: #e23e3e;
                        text-shadow: none;
                        color: #9c0808;
                        padding: 0 5px;
                        border-bottom: solid #9c0808;
                    }
                }
            }
        }

        @include res($break) {
            display: block;

            .item_img {
                width: 100%;
            }

            .item_box {
                width: 100%;
            }
        }
    }

    .seco_container {
        pointer-events: none;
        position: absolute;
        top: -3px;
        right: -3px;
        width: 89px;
        height: 91px;
        overflow: hidden;

        span {
            @include fontsize(18);
            font-weight: bold;
            display: inline-block;
            position: absolute;
            padding: 7px 0;
            left: -23px;
            top: 22px;
            width: 160px;
            text-align: center;
            line-height: 16px;
            background: #b6611e;
            color: #fff;
            letter-spacing: 0.05em;
            transform: rotate(45deg);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

            &::after,
            &::before {
                content: "";
                position: absolute;
                border-top: 4px solid #6b2f00;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                bottom: -4px;
            }

            &::before {
                left: 9px;
            }

            &::after {
                right: 16px;
            }
        }
    }

    .new_up_container {
        pointer-events: none;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #419390;
        font-family: "Russo One", sans-serif;
        color: #fff;
        width: 80px;
        border-radius: 50%;
        text-align: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        &:after {
            content: "NEW\AUP!";
            white-space: pre;
        }
    }

    @include hack(11) {
        dl {
            dt {
                flex: auto !important;
                min-height: 40%;
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }

    @include res($res800) {
        dl {
            .item_credit {
                span {
                    display: block;
                }
            }
        }
    }

    @include res($break) {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 30px;
        }
    }
}

#orderDay {
    color: $main_color_li;
    font-weight: bold;
}

.ptn_backBtn {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: $main_color_li;
    color: #fff;

    @include res($break) {
        display: block;
    }
}

//kaitori

.ptn_h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $main_color;
    text-align: left;
    padding: 5px 5px 5px 10px;
    margin-bottom: 10px;
    border-left: 10px solid $main_color_li;
    color: #fff;
}

.ptn_kaitoriLastBtm {
    margin-bottom: 30px;
}

//cart
.ptn_crtBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    @include fontsize(14);
    color: #252525;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        width: 15%;
        padding: 10px;

        span {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        a {
            color: #b41e23;

            &:hover {
                text-decoration: underline;
            }
        }

        &:nth-child(2) {
            flex: 1;

            @include hack(11) {
                flex: auto;
            }
        }
    }
}

.ptn_submit_grn {
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    text-align: center;
    padding: 1px 6px;
    height: 30px;
    width: 50px;
    background-color: #81b827;
    transition: 0.3s;
    color: #fff;

    &:hover {
        background-color: #609011;
        transition: 0.3s;
    }
}

.ptn_submit {
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    text-align: center;
    padding: 1px 6px;
    height: 30px;
    width: 100%;
    background-color: #b41e23;
    transition: 0.3s;
    color: #fff;

    &:hover {
        background-color: #6c1215;
        transition: 0.3s;
    }
}

.ptn_shop_btm {
    padding-top: 10px;
    display: flex;
    justify-content: center;

    @include res($break) {
        flex-direction: column;

        span {
            display: block;
            width: 200px;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.ptn_moreBtn {
    display: block;
    margin: 0 auto;

    @include hack(11) {
        margin: 0 20px 0 0;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: block;
        @include fontsize(16);
        padding: 10px 20px;
        box-sizing: border-box;
        border: none;
        margin: auto;
        color: #fff;
        transition: all 0.3s;
    }
}

.ptn_backCart {
    a {
        background-color: $gray_li;
    }
}

.ptn_marginTop10 {
    margin-top: 70px;

    &:first-of-type {
        margin-top: 0;
    }
}

.ptn_marginLeft40 {
    margin-left: 100px;

    li {
        &:first-child {
            > p {
                .must {
                    color: $gray_li;
                }
            }

            &::after {
                border-left-color: $gray_li;
            }
        }
    }

    @include res($res800) {
        margin-left: 0;

        li {
            &:first-child {
                &::after {
                    border-top-color: $gray_li;
                    border-left-color: transparent;
                }
            }
        }
    }
}

//item_page

.ptn_itemDd {
    width: 50%;
    display: flex;
    border-top: 1px solid #989898;
    height: 54px;
    line-height: 1.2;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        box-sizing: border-box;
        text-align: center;

        &:first-child {
            background-color: $gray;
            color: #fff;
            width: 50%;
            position: relative;
        }

        &:last-child {
            width: 50%;
            background-color: #fff;
            padding-left: 40px;
            color: #333;
            text-align: left;
        }
    }

    &:nth-of-type(-n + 2) {
        border-top: none;
    }

    @include res($res800) {
        flex-wrap: wrap;
        width: 100%;

        &:nth-of-type(-n + 2) {
            border-top: 1px solid #989898;
        }

        &:first-of-type {
            border-top: none;
        }
    }

    @include res($break) {
        @include fontsize(14);

        div {
            &:first-child {
                width: 40%;
            }

            &:last-child {
                width: 60%;
            }
        }
    }
}

.ptn_itemDdCredit {
    display: flex;
    justify-content: flex-end;
    height: 54px;
    margin-bottom: 5px;

    div {
        display: flex;

        &:first-child {
            width: 20%;
            justify-content: center;
            align-items: center;
            background-color: $gray;
            color: #fff;
        }

        &:last-child {
            width: 30%;
            justify-content: flex-end;
            align-items: center;
            color: #333;
            background-color: #fff;
            padding-right: 10px;
        }
    }

    .special_price {
        @include fontsize(20);
        color: #de5d50 !important;
        font-weight: bold;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include res($break) {
        div {
            &:first-child {
                width: 40%;
            }

            &:last-child {
                width: 60%;
            }
        }
    }
}

.ptn_phoneBlock {
    display: none;

    @include res($break) {
        display: block !important;
    }
}

.ptn_phoneNone {
    display: block;

    @include res($break) {
        display: none !important;
    }
}

.ptn_phoneNoneFlex {
    display: flex;

    @include res($break) {
        display: none !important;
    }
}

.ptn_fontwhite {
    color: #fff;
}
