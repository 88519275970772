@charset "UTF-8";

.mypage_iframe {
    background-color: #fff;
    padding-top: 50px;
    overflow: hidden;
    @include res_min($res900) {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
}

.mypage_iframe__img {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
    li {
        border-radius: 6px;
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-right: 1%;
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:last-child {
            margin-right: 0;
        }
        &::after {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }
}

.mypage_iframe__title {
    @include fontsize(16);
    font-weight: bold;
    text-align: justify;
    padding-bottom: 20px;
}

.mypage_iframe__menu {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    a,
    button {
        @include m1plus;
        font-weight: bold;
        color: #fff;
        display: flex;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        width: calc(50% - 2.5px);
        padding: 10px;
        @include fontsize(16);
        background-color: $gray2;
        margin-right: 5px;
        margin-bottom: 5px;
        &:nth-child(even),
        &:last-child {
            margin-right: 0;
        }
    }
    .mypage_iframe__kakunin {
        background-color: darken($green, 20%);
    }
    .mypage_iframe__edit {
        background-color: darken($blue, 20%);
    }
    .mypage_iframe__baiyaku {
        background-color: $red;
    }
    .mypage_iframe__syoudan {
        background-color: darken($yellow, 20%);
    }
    .mypage_iframe__hyouji {
        background-color: #fff;
        border: 3px solid #555;
        color: #333;
        &.width100 {
            width: 100%;
        }
    }
    .mypage_iframe__change {
        background-color: darken($yellow, 20%);
        width: 100%;
        &.chage_rental__no {
            background-color: $red;
        }
        &.chage_rental {
            background-color: $blue;
        }
    }
    .no_active {
        cursor: not-allowed;
        background-color: $gray2;
        color: #999;
        &::before {
            content: "\f1f6";
            font-family: "Font Awesome 5 Free";
            margin-right: 10px;
        }
    }

    .mypage_iframe__wrap {
        display: flex;
        width: 100%;
    }
    .mypage_iframe__btmBtn {
        color: #fff;
        margin-top: 40px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        &.dleate_btn {
            background-color: darken($red, 20%);
        }
        &.reboot_btn {
            background-color: darken($blue, 50%);
        }
    }
}
