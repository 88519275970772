@charset "UTF-8";

#image_drop_area {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

#image_drop_area::before {
    content: "";
    display: block;
    padding-top: 100%;
}

#preview_area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
}

.drop_box {
    background: #f0f0f0;
    position: relative;
    z-index: 999;
    width: 100%;
}

.drop_box::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    background-image: url("./images/droparea.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30%;
    pointer-events: none;
}
