@charset "UTF-8";

.br_break {
    display: none;
    @include res($break) {
        display: block;
    }
}

.ma20t,
.m20t {
    margin-top: 20px;
}
