@charset "UTF-8";

.mypage_tools--block01 {
    margin-bottom: 10px;
    text-align: left;
}

.mypage_tools--catalog {
    width: 50%;
    max-width: 460px;
    display: block;
    margin: auto;
    margin-bottom: 50px;
    @include res($break) {
        width: 80%;
    }
}

.mypage_tools--carttable {
    margin-bottom: 10px;
    .mypage_tools--carttable1 {
        width: 100%;
    }
}

.mypage_tools--block02 {
    input[type="submit"] {
        display: flex;
        justify-content: center;
        width: 100px;
        margin-left: 10px;
    }
}

@include res($break) {
    .mypage_tools--block02.quicksearch.pr_submit {
        flex-direction: row;
    }
}
