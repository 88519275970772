@charset "UTF-8";

// $res1800: 1800;
// $res1700: 1700;
// $res1600: 1600;
// $res1500: 1500;
// $res1550: 1550;
// $res1400: 1400;
// $res1300: 1300;
$res1200: 1200;
$res1100: 1100;
// $res1024: 1024;
$res1000: 1000;
$res900: 900;
$res800: 800;
$break: 767;
// $res600: 600;
$res500: 500;
// $res400: 400;
$iphoneplus: 414;
$iphone: 375;
$iphone5: 320;

/////////////変数/////////////
$main_color: #f3ba56;
$main_color_li: #eedab6;

$sub_color: #7c623d;
$sub_color_li: #b8935e;
$sub_color_da: #372b1b;

$gray: #434343;
$gray_li: #a9a9a9;
$gray_li_li: #f0f0f0;

$gray2: #dddddd;

$red: #c95757;
$yellow: #c3ba57;
$yellow2: #f3e56f;
$yellow2_li: #fffb87;
$green: #8fc593;
$blue: #5c99df;
$orange: #eca936;
$pink: #f37fd0;
$violet: #b465f5;

/////////////font/////////////
@import url("https://use.fontawesome.com/releases/v5.4.1/css/all.css");

@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,900");
@import url("https://fonts.googleapis.com/css?family=Nanum+Brush+Script");
@import url("https://fonts.googleapis.com/css?family=Teko");
@import url("https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c");

@mixin m1plus {
  font-family: "M PLUS Rounded 1c";
}

@mixin noto {
  font-family: "Noto Sans JP", sans-serif;
}

@mixin tegaki {
  font-family: "Nanum Brush Script", cursive;
}

@mixin teko {
  font-family: "Teko", sans-serif;
}

@mixin yugo {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ ゴシック", sans-serif;
}

// @mixin font01 {
//   font-family: 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
// }
// @mixin font01 {
//   font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
// }

/////////////ベタ組み/////////////
@mixin beta {
  text-align: justify;
  letter-spacing: 0.01em;
}

/////////////影レベル/////////////
@mixin shadow1 {
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.6));

  @include hack(11) {
    box-shadow: (4px 4px 4px rgba(0, 0, 0, 0.6));
  }
}

@mixin shadow2 {
  filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.6));

  @include hack(11) {
    box-shadow: (8px 8px 8px rgba(0, 0, 0, 0.6));
  }
}

@mixin fontsize($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}

@mixin boxafter {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

/////////////opacity/////////////

@mixin opacity($type: 0.6) {
  filter: alpha(opacity=$type * 100);
  -moz-opacity: $type;
  opacity: $type;
}

/////////////レスポンシブ/////////////

@mixin res($size) {
  @media only screen and (max-width: $size + "px") {
    @content;
  }
}

@mixin res_min($size) {
  @media only screen and (min-width: $size + "px") {
    @content;
  }
}

//br
.br_800 {
  display: none;

  @include res($res800) {
    display: block;
  }
}

.br_500 {
  display: none;

  @include res($res500) {
    display: block;
  }
}

// IE＆Firefoxハック用mixin
@mixin hack($ver: 11) {
  @if $ver==11 {

    // IE11以上
    @at-root _:-ms-fullscreen,
    :root & {
      @content;
    }
  }

  @else if $ver==fox {

    // Firefox
    @-moz-document url-prefix() {
      @content;
    }
  }

  @else if $ver==ch {

    // Chrome
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      @content;
    }
  }

  @else if $ver==ed {

    // Edge
    @supports (-ms-ime-align: auto) {
      @content;
    }
  }
}