@charset "UTF-8";

.change_pass {
    dt {
        display: block;
    }
    @include res($break) {
        dd {
            width: 100%;
            input[type="password"] {
                max-width: 100%;
            }
        }
    }
}

.listType_zip {
    @include res($break) {
        justify-content: flex-start !important;
    }
}

.change_mail {
    display: flex;
    flex-wrap: wrap;
    > dd {
        height: auto;
    }
    .change_mail--devaice {
        > dt {
            flex: 1 1 0%;
            margin-right: 10px;
        }
        .pr_radiobox {
            width: 120px;
            &:first-of-type {
                margin-right: 10px;
            }
        }
    }
    .change_mail--devaice,
    .change_mail--type {
        width: 100%;
        display: flex;
        dd {
            display: flex;
            ul {
                display: flex;
            }
        }
    }
    @include res($res1000) {
        display: block;
        .change_mail--devaice {
            display: block;
            > dt {
                height: 50px;
                margin-right: 0;
                margin-bottom: 5px;
            }
            > dd {
                ul {
                    width: 100%;
                    justify-content: space-between;
                    .pr_radiobox {
                        width: 49%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.change_time {
    > dd {
        height: auto;
    }
    .pr_formSelect {
        width: 100%;
        display: flex;
        .pr_radiobox {
            &:first-of-type {
                margin-right: 10px;
            }
            &:last-of-type {
                flex: 1 1 0%;
            }
        }
    }
    @include res($res1000) {
        > dd {
            .pr_formSelect {
                display: block;

                .pr_radiobox {
                    &:first-of-type {
                        margin-bottom: 5px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    @include res($break) {
        display: block;
    }
}

.change_baseinfo,
.change_mailbox {
    margin-bottom: 50px;
}

.change_mail--type {
    margin-top: 5px;
    flex-wrap: wrap;
    dt {
        text-align: left;
        width: 100%;
        margin-bottom: 5px;
        span {
            display: inline-block;
            padding-bottom: 3px;
            border-bottom: 3px solid $gray2;
        }
    }
}

.shop_info_bank {
    > dd {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        .shop_info_bank--kouza {
            display: flex;
            flex-wrap: wrap;
        }
        dl {
            &.shop_info_bank--name,
            &.shop_info_bank--shiten {
                width: 40%;
            }
            &.shop_info_bank--kouza,
            &.shop_info_bank--num,
            &.shop_info_bank--meigi {
                width: 30%;
            }
            &:first-child,
            &.shop_info_bank--kouza,
            &.shop_info_bank--num {
                margin-right: 5px;
            }
            &:nth-child(n + 3) {
                margin-top: 5px;
            }
            dt {
                text-align: left;
            }
            dd input {
                height: auto !important;
            }
        }
    }
}

.shop_info_imgbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    > dl {
        width: 32%;
        margin-top: 20px;
    }
    dt {
        background-color: $gray2;
        border-radius: 6px;
        padding: 5px;
        margin-bottom: 10px;
    }
    dd {
        display: flex;
        flex-direction: column;
        input[type="file"] {
            height: auto !important;
            margin-bottom: 10px;
        }
    }
    &::after {
        content: "";
        display: block;
        width: 32%;
        margin-top: 20px;
    }
}

.shop_info_title {
    width: 100%;
    &::after {
        padding-top: 5px;
        display: block;
        content: "";
        width: 200px;
        border-bottom: 1px solid #ddd;
        margin-left: auto;
        margin-right: auto;
    }
}

.shop_info_textbox {
    > dd {
        height: auto;
    }
}

.shop_info_tokutei {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    .num {
        padding-bottom: 5px;
    }
    textarea {
        text-align: center;
        @include fontsize(20);
        width: 100%;
        font-weight: bold;
        padding: 10px;
        border: 1px solid #434343;
        border-radius: 6px;
    }
    aside {
        @include fontsize(14);
    }
}

.shop_info_pay {
    > dd {
        display: block;
        height: auto;
    }
    .top {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
    .btm {
        display: flex;
        li {
            text-align: left;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    input {
        height: auto !important;
    }
}

.pr_objectfit75 {
    position: relative;
    width: 100%;
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &[src*="noimage"] {
            border: 1px solid #ddd;
        }
    }
    &::before {
        content: "";
        display: block;
        padding-top: 75%;
    }
}
.pr_objectfit100 {
    position: relative;
    width: 100%;
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &[src*="noimage"] {
            border: 1px solid #ddd;
        }
    }
    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}
