@charset "UTF-8"; //topへ戻る

#scrollTop {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    bottom: 20px;
    padding: 4px;
    position: fixed;
    right: 20px;
    cursor: pointer;
    @include fontsize(20);
    line-height: 20px;
    // background-color: $goldc8;
    color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 4px;
    transition: all 0.2s;

    &:hover {
        // background-color: $gold95;
    }
}

#mainbutton {
    display: none;
    cursor: pointer;

    .button_span {
        width: 35px;
        height: 24px;
        position: relative;
        z-index: 999;

        i {
            display: block;
            width: 100%;
            height: 4px;
            // background-color: $goldc8;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin-top: auto;
            margin-bottom: auto;
        }

        .lastchild {
            display: inline-block;
            padding-top: 5px;
            padding-bottom: 36px;
        }

        &::after,
        &::before {
            content: "";
            display: block;
            // background-color: $goldc8;
            width: 100%;
            height: 4px;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .close {
        i {
            display: none;
        }
        // transform: translateX(-100px);
        &::before {
            top: 10px;
            transform: rotate(-45deg);
        }

        &::after {
            bottom: 10px;
            transform: rotate(-135deg);
        }
    }
    @include res($break) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 38px;
    }
}
//***slick**********************************************//
.pr_slick {
    display: block;
    position: absolute;
    z-index: 2;
    right: 46%;
    // background-color: $light_color;
    padding: 30px;
    box-shadow: border-box;
    color: #fff;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: $main_color;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        padding-top: 100%;
    }

    &::before {
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        margin: auto;
        @include fontsize(40);
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
    @include res($res800) {
        right: 45%;
    }
    @include res($res500) {
        right: 40%;
    }
}

.info_up {
    top: -30px;

    &::before {
        top: 15%;
        content: "\f106";
    }
}

.info_down {
    bottom: -30px;

    &::before {
        top: 20%;
        content: "\f107";
    }
}

.new_left {
    top: 50%;
    left: -30px;
    right: inherit;
    @include res(1100) {
        left: -10px;
    }

    &::before {
        top: 17%;
        left: -10%;
        content: "\f104";
    }
}

.new_right {
    top: 50%;
    right: -30px;
    left: inherit;
    @include res(1100) {
        right: -10px;
    }

    &::before {
        top: 17%;
        right: -10%;
        content: "\f105";
    }
}

.slick-dots {
    bottom: -30px;
    right: 0;
    display: flex;
    justify-content: flex-end;

    li {
        &:last-child {
            margin-right: 0;
        }
    }
}
