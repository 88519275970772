@charset "UTF-8";

.shoplist_list--name,
.shoplist_list--course,
.shoplist_list--post,
.shoplist_list--map,
.shoplist_list--tel,
.shoplist_list--fax,
.shoplist_list--mail,
.shoplist_list--tantou,
.shoplist_list--nousan {
    width: 10%;
}

.shoplist_list--table {
    th,
    td {
        padding: 3px;
    }
    tr:not(.list_title) {
        &:hover {
            background-color: lighten($gray2, 8%);
        }
    }
    a {
        background-color: lighten($red, 25%);
        border-radius: 5px;
    }
    @include res($break) {
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        overflow: auto;
        white-space: nowrap;
    }
}

.shoplist_list--nousan {
    width: auto;
}
.shoplist_list--red {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 5px;
    padding-bottom: 10px;
    span {
        color: $red;
        font-weight: bold;
        @include fontsize(20);
        line-height: 1.2;
    }
}
