@charset "UTF-8";

.ft_box {
    position: relative;
    background-image: url("../img/top/top_soil_back.png");

    nav {
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;

        ul {
            background-image: url("../img/footer/foot_line.gif");
            background-repeat: repeat-y;

            li {
                text-align: left;

                a {
                    position: relative;
                    display: inline-block;
                    color: #fff;
                    margin-left: 26px;
                    line-height: 2.5;

                    &::before {
                        font-family: "Font Awesome 5 Free";
                        font-weight: bold;
                        content: "\f0da";
                        transform: rotate(0deg);
                        transition: all 0.2s;
                        padding-right: 8px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 7px;
                        margin: auto;
                        width: 1px;
                        height: 1px;
                        display: block;
                        background-color: transparent;
                        transition: all 0.3s;
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                            transform: scaleX(1);
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        .ft_pdup {
            padding-top: 10px;
        }

        .ft_pdup2 {
            padding-top: 20px;
        }

        .ft_facebook {
            width: 372px;
            position: relative;
            padding-right: 16px;
            padding-left: 16px;
            box-sizing: border-box;

            &::after,
            &::before {
                position: absolute;
                top: 0;
                content: "";
                width: 2px;
                height: 100%;
                background-image: url("../img/footer/foot_line.gif");
                background-repeat: repeat-y;
            }

            &::before {
                right: 0;
            }

            &::after {
                left: 0;
            }
        }
    }

    &::before {
        position: absolute;
        top: -18px;
        content: "";
        display: block;
        width: 100%;
        height: 18px;
        background-image: url("../img/top/top_soil_back_up.png");
    }
    @include res($res900) {
        nav {
            @include fontsize(14);

            .ft_facebook {
                width: 300px;
                iframe {
                    width: 268px;
                }
            }
        }
    }
    @include res($break) {
        nav {
            flex-wrap: wrap;

            .ft_pdup {
                padding-top: 0;
                &:first-child,
                &:nth-child(2) {
                    width: 50%;
                }

                &:nth-child(2) {
                    background-position: right;
                }
                &:nth-child(3) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background-position: right;
                }

                &:nth-child(n + 3) {
                    width: 100%;
                }
            }

            .ft_facebook {
                width: 100%;
                iframe {
                    width: 100%;
                }
            }
        }
    }
}

.ft_btm {
    background-color: $sub_color_da;
    color: #fff;
    padding-top: 32px;
    padding-bottom: 32px;
}

.scroll_top {
    position: fixed;
    bottom: 9px;
    right: 20px;
    background-color: $main_color;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #fff;
}

.facebook-wrapper {
    max-width: 800px;
    margin: 0 auto;
}
.facebook-wrapper > .fb-page {
    width: 100%;
}
.facebook-wrapper > .fb-page > span,
.facebook-wrapper iframe {
    width: 100% !important;
}
