@charset "UTF-8";

.create_box1 {
    p {
        text-align: left;
        margin-bottom: 16px;
    }
    .pr_item_title_gry {
        text-align: left;
        @include fontsize(16);
    }
    .create_warap {
        text-align: left;
        dt,
        dd {
            margin-bottom: 16px;
            .red {
                color: $red;
                font-weight: bold;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .create_example {
            display: flex;
            justify-content: flex-start;
            a {
                @include fontsize(14);
                color: #fff;
                background-color: darken($green, 10%);
                display: inline-block;
                padding: 8px;
                border-radius: 3px;
                margin-right: 8px;
                transition: all 0.2s;
                &:hover {
                    background-color: darken($green, 30%);
                }
            }
            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}

.create_box2 {
    .pr_item_title {
        margin-bottom: 8px;
    }
    .create_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.pr_create_img {
    width: 49%;
    background-color: #fff;
    margin-bottom: 8px;

    figure {
        .up {
            img {
                width: 100%;
                height: auto;
            }
        }
        .bottom {
            .bottom_wrap {
                p {
                    display: block;
                    padding: 8px;
                    @include fontsize(14);
                }
                .create_area {
                    background-color: $gray;
                    color: #fff;
                }
                .create_name {
                    background-color: $gray_li;
                }
                .create_tag {
                    background-color: $gray_li_li;
                    span {
                        @include fontsize(13);
                        display: inline-block;
                        background-color: darken($blue, 5%);
                        border-radius: 3px;
                        padding: 8px;
                        color: #fff;
                        margin-right: 2px;
                        margin-bottom: 2px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    &:hover {
        margin: 1px;
        img {
            opacity: 1;
        }
        @include shadow1;
    }
}
