@charset "UTF-8"; //list

.list_search {
    .type_box {
        display: flex;
        justify-content: space-between;

        li {
            width: 24.784482758%;
            transition: all 0.2s;
            transform: translateY(0);

            a {
                display: block;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 3px 3px 0 0;
                color: #fff;
                background-color: $gray_li;
                @include fontsize(18);
                font-weight: bold;
            }

            &:hover {
                transform: translateY(6px);
            }
        }

        .active {
            a {
                background-color: $yellow2;
                color: #333;
            }
        }
    }

    .order_box {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        border-radius: 0 0 3px 3px;
        background-color: $yellow2;

        li {
            width: 14.2857142857%;

            a {
                display: block;
                padding-top: 10px;
                padding-bottom: 10px;

                &:hover {
                    background-color: $yellow2_li;
                }
            }
        }

        .order_first {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .search_box {
        display: flex;
        background-color: transparent;
        margin-bottom: 10px;
        &--all {
            background-color: lighten($orange, 10%);
            @include fontsize(13);
            padding: 5px;
            border-radius: 6px;
            width: 70px;
        }

        li {
            form {
                div {
                    &::after {
                        top: -2px;
                    }
                }

                input[type="text"],
                select {
                    color: $gray_li;
                    @include fontsize(16);
                    text-align: center;
                    border-bottom: 2px solid $yellow2;
                }

                input[type="submit"] {
                    @include fontsize(18);
                }
            }
        }
    }

    @include res($break) {
        .type_box {
            li {
                width: 23%;
            }
        }

        .order_box {
            flex-wrap: wrap;

            li {
                width: 33.333333333%;

                a {
                    @include fontsize(14);
                }
            }

            .order_first {
                @include fontsize(14);
                background-color: darken($yellow2, 30%);
                color: #fff;
            }
        }

        .search_box {
            flex-direction: column;
            &--all {
                width: 100%;
                br {
                    display: none;
                }
            }
            form {
                display: block;

                div {
                    width: 100%;

                    select {
                        height: 40px;
                        @include fontsize(16);
                    }
                }

                input[type="submit"] {
                    top: 47px;
                    right: 0;
                }
            }
        }
    }
}

.list_pager {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .pager_num {
        text-align: right;
        margin-right: 8px;
    }

    nav {
        display: flex;
        justify-content: space-between;
        border-radius: 3px;
        background-color: $yellow2;

        span {
            display: block;
            width: 100%;

            a {
                display: block;
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    background-color: $yellow2_li;
                }
            }
        }
    }

    &:last-of-type {
        margin-top: 16px;
    }

    @include res($break) {
        display: block;

        .pager_num {
            margin-bottom: 4px;
        }
    }
}

.tab_mypagele {
    .pr_title_sun {
        margin-top: 16px;
    }

    .list_head {
        display: flex;
        justify-content: space-between;
        background-color: $gray_li;
        @include fontsize(13);
        color: #fff;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            border-right: 1px solid $gray;

            &:last-child {
                border-right: 0;
            }
        }

        .img_box {
            width: 20%;
            height: auto;
            overflow: hidden;
            background-image: url("");

            img {
                width: 100%;
                height: auto;
            }
        }

        .price_box {
            @include fontsize(13);
            font-weight: normal;
        }
    }

    @include res($break) {
        .list_head {
            display: none;
        }

        .list_wrap {
            display: flex;
            flex-wrap: wrap;

            a {
                width: 33.333333333%;
                &.SHOP {
                    width: 100%;
                }
                ul {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    height: 100%;

                    li {
                        width: 100%;
                        padding: 8px;

                        &:last-child,
                        &:nth-child(2) {
                            padding: 0;
                        }
                    }

                    .img_box {
                        height: 70px;
                        padding: 0;
                    }

                    .type_box {
                        display: flex;
                        justify-content: flex-start;

                        span {
                            border-radius: 0;
                        }
                    }

                    .price_box {
                        span {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 4px 0;
                            height: 25px;
                            width: 100%;
                            border-radius: 0;
                            text-align: center;
                        }

                        .price_mode {
                            @include fontsize(14);
                            background-color: $gray_li;
                        }
                    }
                }

                &:nth-child(odd) {
                    background-color: #eeeee5;
                }

                &:hover {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }
            }
        }
    }
}

.pr_tab_mypagele {
    display: flex;
    justify-content: space-between;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontsize(13);
        text-align: left;
        padding: 1px;
        box-sizing: border-box;
    }

    .img_box {
        width: 20%;
        height: 130px;
        overflow: hidden;
        background-color: $gray_li;
        background-image: url("../img/top/slide_back2.png");

        img {
            width: 100%;
            height: auto;
        }
    }

    .type_box {
        width: 10%;

        .new_type {
            display: inline-block;
            padding: 5px;
            background-color: $blue;
            color: #fff;
            border-radius: 3px;
        }

        .seco_type {
            display: inline-block;
            padding: 5px;
            background-color: $red;
            color: #fff;
            border-radius: 3px;
        }

        .parts_type {
            display: inline-block;
            padding: 5px;
            background-color: $gray_li;
            color: #fff;
            border-radius: 3px;
        }
    }

    .model_box {
        width: 12.5%;
    }

    .maker_box {
        width: 12.5%;
    }

    .num_box {
        width: 15%;
    }

    .area_box {
        width: 15%;
    }

    .price_box {
        width: 15%;
        font-weight: bold;

        .sale_mode {
            display: inline-block;
            padding: 5px;
            background-color: $red;
            color: #fff;
            border-radius: 3px;
        }

        .negotiate_mode {
            display: inline-block;
            padding: 5px;
            background-color: $yellow;
            color: #fff;
            border-radius: 3px;
        }

        .consultation_mode {
            display: inline-block;
            padding: 5px;
            background-color: $green;
            color: #fff;
            border-radius: 3px;
        }
    }

    @include res($res900) {
        li {
            @include fontsize(10);
        }
    }
}

.pr_list_link {
    display: block;
    transform: scale(1);
    transition: all 0.2s;
    background-color: #fff;

    &:nth-of-type(2n) {
        background-color: #e1e1d9;
    }

    &:hover {
        @include shadow1;
        transform: scale(1.01);
        margin-top: 8px;
        margin-bottom: 8px;

        img {
            opacity: 1;
        }
    }

    @include res($break) {
        &.SHOP {

        }
    }
}

//detail

.detail_img {
    .title {
        text-align: left;
        margin-bottom: 8px;

        .num {
            display: flex;
            margin-bottom: 5px;

            div {
                box-sizing: border-box;

                &:first-child {
                    padding: 5px 20px;
                    background-color: $main_color;
                    color: #fff;
                }

                &:last-child {
                    padding: 5px 50px;
                    background-color: $main_color_li;
                }
            }
        }

        h1 {
            font-weight: bold;
            line-height: 1.1;
        }
    }

    figure {
        margin-bottom: 16px;

        img {
            width: 100%;
            height: auto;
        }

        .main_thumb {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;

            .main {
                width: 66.4%;
            }

            .thumb02_03 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 32.8%;
            }
        }

        .thumb04_09 {
            display: flex;

            div {
                width: 16%;
                margin-right: 0.8%;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .mobail {
            display: none;

            li {
                margin: 2px;
            }
        }
    }

    // .itemPage_info01Base {
    //     display: flex;
    //     flex-wrap: wrap;
    //     padding: 0;
    //     background-color: transparent;
    // }
    //
    // .itemPage_info04Rela {
    //     display: flex;
    //     flex-wrap: wrap;
    //
    //     dt {
    //         width: 100%;
    //     }
    //
    //     dd {
    //         width: 32.5%;
    //         margin-right: 1.25%;
    //
    //         a {
    //             display: block;
    //
    //             img {
    //                 width: 100%;
    //                 height: auto;
    //             }
    //         }
    //
    //         p {
    //             text-align: left;
    //             margin-top: 5px;
    //
    //             &:last-child {
    //                 margin-top: 0;
    //             }
    //         }
    //
    //         &:last-child {
    //             margin-right: 0;
    //         }
    //     }
    //     @include res($break) {
    //         flex-direction: column;
    //
    //         dt {
    //             width: auto;
    //         }
    //
    //         dd {
    //             width: 100%;
    //             margin-right: 0;
    //             margin-bottom: 10px;
    //
    //             &:last-child {
    //                 margin-bottom: 0;
    //             }
    //         }
    //     }
    // }
}

.detail_des {
    p {
        padding: 8px;
        box-sizing: border-box;
        text-align: left;
    }
}

.detail_price {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 80px;

    .pr_submit {
        input[type="submit"] {
            display: block;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .inq {
            background-color: $green;
            transition: all 0.2s;

            &:hover {
                background-color: darken($green, 10%);
            }
        }
    }
}

.prdct_inquiry_type {
    li {
        &:last-child {
            display: flex;
            justify-content: space-between;
        }

        p {
            width: 49%;
        }
    }

    @include res($res800) {
        li {
            &:last-child {
                display: block;
            }

            p {
                width: 100%;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.detail_delivery {
    .pr_item_title {
        margin-bottom: 8px;
    }

    .plan_box {
        display: flex;
        justify-content: space-between;

        dl {
            width: 31%;
            border: 1px solid $gray;
            border-radius: 3px;
            margin-bottom: 16px;

            dt {
                background-color: $gray;
            }

            dd,
            dt {
                padding: 8px !important;
            }
        }
    }

    @include res($break) {
        .plan_box {
            display: block;

            dl {
                width: 100%;
            }
        }
    }
}

.detail_relate {
    .pr_item_title {
        margin-bottom: 8px;
    }

    .prdct_sec_relate_wrap {
        display: flex;
        width: 100%;

        article {
            width: 32.631578947%;

            figure {
                div {
                    height: 180px;
                }

                figcaption {
                    padding: 8px;
                    background-color: #fff;
                }
            }
        }

        @include res($break) {
            display: none;
        }
    }

    #slick_ditail_relate {
        display: none;

        @include res($break) {
            width: 100%;
            display: block;

            a {
                display: block;

                figure {
                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 288px;
                        overflow: hidden;
                        background-color: $gray_li;
                        background-image: url("../img/top/slide_back2.png");

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    figcaption {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.detail_shop {
    .pr_item_title {
        margin-bottom: 8px;
    }

    .prdct_sec_shop_wrap {
        display: flex;
        width: 100%;

        figcaption {
            flex: 1 auto;
            margin-right: 8px;

            .pr_cta {
                display: flex;
                justify-content: flex-end;
                margin-top: 8px;

                a {
                    width: auto;
                }
            }
        }

        .img_box {
            >div {
                width: 200px;
                height: 150px;
                overflow: hidden;
                margin-bottom: 8px;

                img {
                    width: 100%;
                    height: auto;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include res($break) {
        .prdct_sec_shop_wrap {
            flex-direction: column-reverse;

            .img_box {
                display: flex;
                justify-content: center;
                margin-bottom: 8px;
            }
        }
    }
}

.ditail_inquiry {
    form {
        width: 100%;

        >p {
            @include fontsize(14);
            margin-bottom: 8px;
            text-align: left;
        }

        .pr_submit {
            border-radius: 3px;
            float: right;

            input {
                background-color: $green;

                &:hover {
                    background-color: darken($green, 10%);
                }
            }
        }

        @include boxafter;
    }
}

//form

.frm_plan {
    dl {
        text-align: left;

        dt {
            color: #fff;
            background-color: $gray_li;
            margin-bottom: 8px;
            padding: 4px;
            box-sizing: border-box;
        }

        dd {
            @include fontsize(14);
            line-height: 1.6;
            padding-bottom: 16px;

            &:last-child {
                padding-bottom: 0;
            }

            &::before {
                content: "・";
            }
        }
    }
}

.frm_inquiry {
    .pr_selectBox {
        &::after {
            top: 11px;
            right: 16px;
        }
    }

    .magazine {
        li {
            &:last-child {
                display: flex;
                justify-content: space-between;

                p {
                    width: 49%;
                }
            }
        }
    }

    .group {
        margin-bottom: 0;

        li {
            &:last-child {
                border-bottom: 1px dotted $gray_li;
            }
        }
    }
}

.frm_pay {
    .pay_way {
        .box1 {
            display: flex;
            justify-content: space-between;

            p {
                width: 31%;
            }
        }

        .box2 {
            .pay_num {
                margin-top: 8px;
                position: relative;
                display: flex;
                @include fontsize(14);

                .left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    border: 1px solid $gray_li_li;
                    border-right: 0;
                    border-radius: 3px 0 0 3px;
                }

                .right {
                    width: 100%;

                    select {
                        border: 1px solid $gray_li_li;
                        padding-left: 16px;
                        padding-right: 32px;
                        box-sizing: border-box;
                        @include fontsize(14);
                    }
                }
            }

            .card {
                display: flex;
                justify-content: space-between;

                div {
                    width: 8%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .box4 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border: 1px solid $gray_li;
            padding: 8px;
            box-sizing: border-box;
            border-radius: 3px;

            .top {
                width: 100%;
                padding-bottom: 8px;
                @include fontsize(14);

                dt {
                    padding-bottom: 8px;
                    box-sizing: border-box;
                    border-bottom: 1px solid $gray_li;
                    margin-bottom: 8px;
                }

                dd {
                    @include beta;
                    padding-bottom: 8px;
                }

                .head {
                    position: relative;
                    display: flex;
                    @include fontsize(14);

                    .left {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100px;
                        background-color: $gray_li_li;
                        border-radius: 3px 0 0 3px;
                    }

                    .right {
                        width: 100%;

                        input {
                            border: 1px solid $gray_li_li;
                            padding-right: 32px;
                            box-sizing: border-box;
                        }
                    }

                    &::after {
                        pointer-events: none;
                        content: "円";
                        position: absolute;
                        top: 12px;
                        bottom: 0;
                        right: 8px;
                        margin: auto;
                    }
                }
            }

            div {
                p {
                    @include fontsize(14);
                }

                width: 48%;
            }
        }

        aside {
            @include beta;
            padding-top: 4px;
            @include fontsize(13);
        }

        .base {
            margin-bottom: 8px;
            padding-bottom: 16px;
            border-bottom: 1px dotted $gray_li;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
    }

    @include res($break) {
        .pay_way {
            .box1 {
                display: block;

                p {
                    width: 100%;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.frm_order {
    display: flex;

    .left {
        width: 30%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .right {
        padding-left: 8px;
        box-sizing: border-box;
        width: 70%;

        .pr_selectBox {
            &::after {
                top: 11px;
                right: 16px;
            }
        }
    }

    @include res($break) {
        display: block;

        .left {
            width: 100%;
            margin-bottom: 8px;
        }

        .right {
            width: 100%;
            padding-left: 0;
        }
    }
}

//done

.done_completion {
    p {
        width: 100%;
        margin-bottom: 32px;
    }

    .pr_submit {
        padding-top: 32px;
        margin: auto;
    }
}