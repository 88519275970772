@charset "UTF-8";

.mypage_narabikaetitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    @include fontsize(16);
    background-color: darken($gray2, 20%);
}

.mypage_acountchange {
    margin-bottom: 50px;
}

.mypage_icon {
    margin-bottom: 50px;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: $gray;
        color: #fff;
        width: 20%;
        border-radius: 50%;
        margin-right: 2%;
        transition: all 0.2s;

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            line-height: 1.2;
        }

        &:last-child {
            margin-right: 0;
        }

        &:nth-child(4n) {
            margin-right: 0;
        }

        &:nth-child(n + 5) {
            margin-top: 10px;
        }

        &:hover {
            transform: scale(1.05);
        }

        &::after {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .icon {
        position: absolute;
        bottom: 30%;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        @include fontsize(50);
        height: 50%;

        .star {
            position: absolute;
            top: 0;
            right: 30px;
            @include fontsize(20);
        }
    }

    .text {
        @include m1plus;
        font-weight: bold;
        color: #fff;
        padding-top: 66%;
    }

    &--red {
        background-color: $red;
    }

    &--old {
        background-color: lighten($red, 5%);
    }

    &--yellow {
        background-color: darken($yellow, 10%);
    }

    &--green {
        background-color: darken($green, 10%);
    }

    &--orange {
        background-color: $orange;
    }

    &--blue {
        background-color: $blue;
    }

    &--pink {
        background-color: $pink;
    }

    &--violet {
        background-color: $violet;
    }

    &--gray {
        background-color: $gray;
    }

    .new_touroku {
        background-color: $red;
    }

    .old_touroku {
        background-color: $red;
    }

    .keijiban,
    .creditcard {
        background-color: $blue;
    }

    .creditcard {
        @include fontsize(14);
    }

    .rental {
        background-color: $yellow;
    }

    .old_page {
        background-color: $gray;
    }

    .ordertype {
        background-color: #fff;
        border: 3px solid $gray2;

        &.orico {
            .icon {
                width: 70%;
            }
        }

        &.remise {
            .icon {
                width: 70%;
            }
        }

        &.trusco {
            .icon {
                width: 50%;
            }
        }

        .text {
            color: $gray;
        }
    }

    @include res($res900) {
        ul {
            flex-wrap: wrap;
        }

        li {
            width: 30%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(n + 4) {
                margin-top: 10px;
            }
        }

        .icon {
            font-size: calc(8vw + 10px);

            .star {
                right: 12%;
                @include fontsize(16);
            }
        }

        .text {
            font-size: calc(0.5vw + 10px);
        }
    }
}

.mypage_narabikae {
    height: 50px;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    background-color: $gray2;
    border-radius: 6px;

    .narabikae_wrap {
        display: flex;
        align-items: center;
        flex: 1 1 0%;
        padding: 10px;
    }

    dl {
        display: flex;
        justify-content: center;
        margin-right: 2%;

        dt {
            display: flex;
            align-items: center;

            &::after {
                content: "/";
                padding-right: 5px;
                padding-left: 5px;
            }
        }

        dd {
            a {
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 3px 10px;
                background-color: #fff;
                transition: all 0.2s;

                &:hover {
                    background-color: lighten($yellow, 10%);
                }
            }

            &.new {
                a {
                    border-radius: 3px 0 0 3px;
                    border-right: 1px solid $gray2;
                }
            }

            &.old {
                a {
                    border-radius: 0 3px 3px 0;
                    border-left: 1px solid $gray2;
                }
            }

            select {
                transition: all 0.2s;

                &:hover,
                &:focus {
                    background-color: lighten($yellow, 10%);
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .narabikae_kakaku {
        flex: 1 1 0%;
        justify-content: flex-start;

        dd {
            position: relative;
            display: flex;
            flex: 1 1 0%;

            &::after {
                pointer-events: none;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 15px;
                margin-top: auto;
                margin-bottom: auto;
                width: 10px;
                height: 10px;
                border: 0;
                border-bottom: solid 2px $gray;
                border-right: solid 2px $gray;
                transform: rotate(45deg);
            }
        }

        select {
            flex: 1 1 0%;
            border-radius: 3px;
            display: flex;
            align-items: center;
            border-radius: 3px;
            padding: 3px 10px;
            border: none;
            @include fontsize(16);
            appearance: none;
        }
    }

    .reset_btn {
        a {
            @include fontsize(12);
            border-radius: 10px;
            background-color: $blue;
            color: #fff;

            &:hover {
                background-color: darken($blue, 10%);
            }
        }
    }

    @include res($res1000) {
        @include fontsize(13);
        height: auto;

        .pr_mypageTitle {
            width: 100%;
        }

        .narabikae_wrap {
            flex-wrap: wrap;
            width: auto;

            dl {
                width: 50%;
                margin-right: 0;

                &:first-child {
                    padding-right: 2%;
                }
            }

            dd {
                &.new {
                    flex: 1 1 0%;
                }

                &.old {
                    flex: 1 1 0%;
                }
            }

            .narabikae_kakaku {
                margin-top: 5px;
                line-height: 1.1;
                width: 100%;
                padding-right: 2%;
            }

            .reset_btn {
                margin-top: 5px;
                width: auto;
            }
        }
    }
}

.quicksearch {
    position: relative;
    margin-bottom: 10px;

    .qs_label {
        display: none;
    }

    input[type="text"] {
        border-radius: 6px;
        border: 1px solid #ddd;
        width: 100%;
        @include m1plus;
        font-weight: bold;
        @include fontsize(20);
        padding: 10px;
        padding-left: 84px;
        transition: all 0.2s;

        &:hover,
        &:focus {
            background-color: lighten($yellow, 30%);
        }
    }

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        align-items: center;
        left: 26px;
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        @include fontsize(30);
        color: $gray;
    }

    #loader {
        display: none !important;
    }
}

.table_exp {
    width: 100%;
    @include fontsize(13);
    border-collapse: collapse;
    border: 1px solid #ddd;
    border-radius: 1px;

    th,
    td {
        border: 1px solid #ddd;
    }

    a {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 5px;
    }
}

.list_title {
    background-color: $green;

    td {
        height: 40px;
    }

    .list_title__jjoutai {
        width: 50px;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
            transition: all 0.2s;
            background-color: darken($green, 10%);
        }
    }
}

.list_product {
    width: 100%;

    .list_product__syouhinmei {
        background-color: rgba(#ffffee, 0.5);
    }

    .list_product__maker {
        background-color: rgba(#e5ffe5, 0.5);
    }

    .list_product__katashiki {
        background-color: rgba(#ffffee, 0.5);
    }

    .list_product__kakaku {
        background-color: rgba(#ffffdd, 0.5);
        white-space: nowrap;
    }

    .list_product__joutai {
        width: 50px;
    }

    .state {
        text-align: center;
        width: 80%;
        border-radius: 1px;
        color: #fff;

        &.oudan {
            background-color: $blue;
        }

        &.baiyaku {
            background-color: $red;
        }

        &.syoudan {
            background-color: $yellow;
        }

        &.new_type {
            background-color: $red;
        }

        &.seco_type {
            background-color: $blue;
        }

        &.rental_ok {
            background-color: $blue;
        }

        &.rental_no {
            background-color: $red;
        }
    }

    .other_type {
        a {
            margin-top: -5px;
        }
    }

    &:hover {
        transition: all 0.2s;
        background-color: darken(#f6f6f6, 10%);
    }
}

table.products_list td a {
    display: block;
    /* リンクをブロックレベル化して表示 */
    padding: 0.6em 1em;
    /* 内側の余白量を上下に0.6文字分＆左右に1文字分にする */
}

////////////////////////////////////////

.tab_mypage {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    a {
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $green;
        border: 3px solid $green;
        border-bottom: 0;
        border-radius: 3px 3px 0 0;
        text-align: center;
        cursor: pointer;
        -webkit-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
        padding-top: 18px;
        padding-bottom: 15px;
        font-weight: 700;
        height: 54px;
    }

    li {
        width: 13%;
        transition: all 0.2s;

        &.current {
            pointer-events: none;
            transform: translateY(4px);

            a {
                background-color: #fff;
            }
        }

        &:hover {
            transform: translateY(4px);

            a {
                background-color: #fff;
            }
        }
    }

    @include res($break) {
        li {
            @include fontsize(14);
        }
    }
}

.movie_table {
    @include res($break) {
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        overflow: auto;
        white-space: nowrap;
    }
}

.list_movie__num {
    width: 38px;
    padding: 5px;
}

.list_movie__img {
    width: 20%;

    @include res($break) {
        button {
            width: 120px;
        }
    }
}

.list_movie__cat,
.list_movie__maker {
    padding: 10px;
    width: 10%;
}

.list_movie__upday {
    @include fontsize(10);
    width: 41px;
    padding: 5px;
}

dd.list_movie__upday {
    text-align: justify;
}

td.list_movie__title {
    padding: 10px;
    text-align: justify;
}

.mypage_movie {
    margin-bottom: 10px;
}

.mypage_deleate {
    display: flex;
    justify-content: flex-end;

    input {
        width: auto;
        padding: 10px !important;
    }

    .execute {
        width: auto !important;
    }

    .deleate {
        color: #333 !important;
        background-color: $gray2 + !important;
        margin-right: 10px;

        @include res($break) {
            margin-right: 0;
        }
    }
}