@charset "UTF-8";

.ft_form {
    @include fontsize(16);
    @include res($res800) {
        @include fontsize(14);
    }
    @include res($res500) {
        @include fontsize(13);
    }
}

.ft_16_13_ {
    @include fontsize(16);
    @include res($res800) {
        @include fontsize(13);
    }
}

.ft_20_16_ {
    @include fontsize(20);
    @include res($break) {
        @include fontsize(16);
    }
}

.ft_30_20_ {
    @include fontsize(30);
    @include res($break) {
        @include fontsize(20);
    }
}
