@charset "UTF-8";

.mypage_movie_wrap {
    display: flex;
    border-bottom: 1px solid $gray2;

    .movie_left {
        flex: 1 1 0%;
        .ft_small {
            input[type="text"] {
                @include fontsize(12);
            }
        }
        .mypage_movie--description {
            border-bottom: 0;
            dd {
                height: auto;
            }
        }
    }
    .movie_right {
        width: 300px;
        padding-left: 20px;
        .pr_listType1 {
            flex-direction: column;
        }
    }

    @include res(1160) {
        display: block;

        .movie_left {
            .mypage_movie--description {
                border-bottom: 1px solid $gray2;
            }
        }
        .movie_right {
            width: 100%;
            padding-left: 0;
        }
    }
    @include res($break) {
        .movie_right {
            .pr_listType1 {
                &.thumbnail {
                    .thumbnail_input {
                        padding-bottom: 10px;
                    }
                    .thumbnail_img {
                        position: static;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.mypage_movie_submit {
    padding-top: 20px;
}
