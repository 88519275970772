@charset "UTF-8";

// html,
// body,
// .sub_main {
// 	height: 100%;
// }

// .sub_main {
// 	min-height: 100%;
// }

html {
	// font-family: Quicksand,'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
	@include noto;
	font-size: 16px;
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
	position: relative;
	color: #333;
	line-height: 1.4;
	text-align: center;
	z-index: 0;
	word-break: break-all;
	z-index: 0;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	@include hack(11) {
		background-color: $main_color;
		* {
			min-height: 0%;
		}
	}
}

main {
	@include hack(11) {
		display: block;
	}
}

// a {
// 	transition: all 0.2s;
// 	&:hover {
// 		opacity: 0.8;
// 	}
// }

address,
body,
blockquote,
caption,
dd,
div,
dl,
dt,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
li,
object,
ol,
p,
pre,
table,
td,
th,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
}

.content_out {
	padding-top: 80px;
	padding-bottom: 200px;
}

* {
	box-sizing: border-box;
}

////////////　フォ－ム

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
	font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
}

// input,
// select {
// 	-webkit-appearance: none;
// 	border-radius: 0;
// }

select {
	cursor: pointer;
	background-color: #fff;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-ms-expand {
	display: none;
}

textarea {
	overflow: auto;
}

li {
	list-style: none;
}

a,
button,
input[type="submit"],
.togglePluse_pulus {
	transition: all 0.1s;
	-webkit-transform: translatez(0);
	color: #333;
	&:hover {
		opacity: 0.8;
	}
}

a:active,
a:hover,
a:link,
a:visited {
	outline: none;
	transition: all 0.1s;
	color: #333;
	text-decoration: none;
}

a:hover img {
	opacity: 0.8;
	cursor: pointer;
}

a,
ins {
	text-decoration: none;
}

address,
cite,
dfn,
em,
var {
	font-style: normal;
}

input,
textarea {
	font-family: sans-serif;
	font-weight: normal;
}

img {
	vertical-align: bottom;
	width: 100%;
	height: auto;
}

a img {
	border: none;
}

img[src$=".svg"] {
	width: 100%;
}

button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
	border-radius: 0;
	-webkit-box-sizing: content-box;
	-webkit-appearance: button;
	appearance: button;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
	display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
	outline-offset: -2px;
}
