@charset "UTF-8";

.login_body {
    background-color: $gray2;
    height: 100vh;
    position: relative;
}

.login_main {
    padding-top: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .login_wrap {
        position: relative;
        width: 100%;
        padding: 100px 20px 20px 20px;
        border-radius: 6px;
        background-color: #fff;
        filter: drop-shadow(1px 1px 2px #000);
    }
    .login_img {
        display: block;
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 130px;
        background-color: #fff;
        border-radius: 50%;
        padding: 10px;
        border: 3px solid $gray2;
    }
    .login_title {
        @include fontsize(18);
        margin-bottom: 30px;
    }
    .maxwidth {
        width: 100%;
        max-width: 400px;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
    }
    .login_btn {
        margin-top: 50px;
    }
    .login_btn--back {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $gray2;
        border-radius: 0 0 0 6px;
        padding: 10px;
    }
}

.login_info {
    dt {
        text-align: left;
        margin-bottom: 5px;
    }
}

.login_user,
.login_pass {
    position: relative;
    &::before {
        pointer-events: none;
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        z-index: 10;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 20px;
        margin: auto;
        display: flex;
        align-items: center;
    }
    &:hover {
        &::before {
            color: $red;
        }
    }
}

.login_user {
    margin-bottom: 20px;
    &::before {
        content: "\f007";
    }
}
.login_pass {
    &::before {
        content: "\f084";
    }
}

.login_input {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 6px 12px 6px 50px;
    border-radius: 6px;
    border: 1px solid $gray;
    transition: all 0.2s;
    @include m1plus;
    @include fontsize(20);
    font-weight: bold;
    &:hover,
    &:focus {
        background-color: lighten($main-color, 20%);
    }
}

.login_backtop {
    padding-top: 20px;
}
